<template>
  <div class="home">
    <Lesson1Content msg="Lesson 1"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson1Content from '@/components/Lesson1Content.vue'

export default {
  name: 'Lesson1',
  components: {
    Lesson1Content
  }
}
</script>
