<template>
  <div class="home">
    <Lesson4BExercise msg="Exercise 4-B"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson4BExercise from '@/components/Lesson4BExercise.vue'

export default {
  name: 'Exercise4B',
  components: {
    Lesson4BExercise
  }
}
</script>
