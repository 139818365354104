<template>
  <div class="home">
    <certificateTemplate3 msg="certificateTemplate3"/>
  </div>
</template>

<script>
// @ is an alias to /src
import certificateTemplate3 from '@/components/certificateTemplate3.vue'

export default {
  name: 'certificate3',
  components: {
    certificateTemplate3
  }
}
</script>
