<template>
  <div class="home">
    <Lesson1CExercise msg="Exercise 1-C"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson1CExercise from '@/components/Lesson1CExercise.vue'

export default {
  name: 'Exercise1C',
  components: {
    Lesson1CExercise
  }
}
</script>
