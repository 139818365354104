<template>
  <div class="home">
    <Lesson6AExercise msg="Exercise 6-A"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson6AExercise from '@/components/Lesson6AExercise.vue'

export default {
  name: 'Exercise6A',
  components: {
    Lesson6AExercise
  }
}
</script>
