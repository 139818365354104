<template>
  <div class="home">
    <Lesson4CExercise msg="Exercise 4-C"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson4CExercise from '@/components/Lesson4CExercise.vue'

export default {
  name: 'Exercise4C',
  components: {
    Lesson4CExercise
  }
}
</script>
