<template>
  <div class="lesson-container">
    <div class="lesson-header-container">
      <h2>{{ lessonQuestion }}</h2>
    </div>
    <div id="lesson-content-container" v-for="(lessonNote, index) in lessonNotes" :key="index">
      <img :src="lessonNote.imageURL" alt="image" v-if="lessonNote.imageURL">
      <p>{{ lessonNote.note }}</p>
    </div>
    <div class="button-container">
      <div id="exercise-button">
        <button class="button" @click="goToExercise">Go To Exercise!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lesson-2-content',
  props: {
    msg: String
  },
  data () {
    return {
      lessonQuestion: 'The Preamp and Power Amplifier',
      lessonNotes: [
        {
          id: 1,
          note: 'As we learned in the previous lesson, preamps and power amplifiers take audio signals up to the next level of audio.',
          imageURL: '/Graphics/FinalSlides/3_1.svg'
        },
        {
          id: 2,
          note: 'Let’s talk more about the preamp.',
          imageURL: ''
        },
        {
          id: 3,
          note: 'The preamp or preamplifier is an electronic amplifier that boosts a weak audio signal to a useable level. ',
          imageURL: '/Graphics/FinalSlides/3_2_1.svg'
        },
        {
          id: 4,
          note: 'Most preamps have an adjustable gain that can be used to set the amount that the audio signal is boosted. This is usually a simple knob on the preamp. The more the knob is turned, the louder the signal gets.',
          imageURL: '/Graphics/FinalSlides/3_2_2.svg'
        },
        {
          id: 5,
          note: 'The preamp is almost always the first piece of gear that any audio will go through because most audio gear is designed to operate at line level. ',
          imageURL: ''
        },
        {
          id: 6,
          note: 'Preamps can be found as a piece of standalone equipment or inside of guitar amplifiers, audio consoles, or any other piece of equipment that accepts microphone level audio.',
          imageURL: '/Graphics/FinalSlides/3_3.svg'
        },
        {
          id: 7,
          note: 'Preamps are a vital piece of the audio console.',
          imageURL: '/Graphics/FinalSlides/3_4_1.svg'
        },
        {
          id: 8,
          note: 'In live audio and some professional studio recording, the preamps are found in the audio console or soundboard. An audio console is a piece of equipment that can take several channels of audio and mix them together. We will talk more about them in another lesson.',
          imageURL: '/Graphics/FinalSlides/3_4_2.svg'
        },
        {
          id: 9,
          note: 'A lot of studios also have preamps that are not inside the console. They are a part of what is called outboard gear and have to be routed into the audio chain past the console’s onboard preamps.',
          imageURL: '/Graphics/FinalSlides/3_4_3.svg'
        },
        {
          id: 10,
          note: 'Every microphone and instrument will operate at a slightly different level. However, they all need to be at relatively the same level before they go into the recorder or sound system.',
          imageURL: '/Graphics/FinalSlides/3_5.svg'
        },
        {
          id: 11,
          note: 'The adjustable gain knob on each preamp needs to be set according to the amount of level boost that that specific channel needs. This ensures that the next piece of equipment in the audio chain gets the amount of level it needs to operate without adding noise.',
          imageURL: ''
        },
        {
          id: 12,
          note: 'Now let’s talk more about the power amplifier.',
          imageURL: ''
        },
        {
          id: 13,
          note: 'Much like a preamp, the power amplifier or power amp boosts the audio signal. However, the power amp takes a line level signal and raises the power enough to physically move a speaker.',
          imageURL: '/Graphics/FinalSlides/3_6.svg'
        },
        {
          id: 14,
          note: 'Power amps can also have an adjustable gain knob but, depending on the application, it might not get adjusted very often.',
          imageURL: ''
        },
        {
          id: 15,
          note: 'Power amps can be found in some small portable consoles, but they are more likely either separate units or built into the speaker itself.',
          imageURL: '/Graphics/FinalSlides/3_7_1.svg'
        },
        {
          id: 16,
          note: 'A sure sign that your speaker has a built-in power amp is if it has a power plug to be plugged into the wall. Never send a speaker level signal into a powered speaker.',
          imageURL: '/Graphics/FinalSlides/3_7_2.svg'
        },
        {
          id: 17,
          note: 'The power amp is almost always the last piece of the audio chain before the signal goes into the (non-powered) speaker.',
          imageURL: ''
        },
        {
          id: 18,
          note: 'Preamps and power amplifiers are found in almost every audio signal chain, but it is essential to know where they belong. A good example that contains both of these pieces is the guitar amp.',
          imageURL: '/Graphics/FinalSlides/3_8.svg'
        },
        {
          id: 19,
          note: 'A signal is output from a guitar and is input into the guitar amplifier.',
          imageURL: '/Graphics/FinalSlides/3_9_1.svg'
        },
        {
          id: 20,
          note: 'The first thing the signal goes through inside the guitar amplifier is the preamp.',
          imageURL: '/Graphics/FinalSlides/3_9_2.svg'
        },
        {
          id: 21,
          note: 'There is usually a gain knob on the front of the amp that adjusts the preamp gain. If it is turned up “too far” the signal distorts. Some people find this desirable in tube guitar amplifiers.',
          imageURL: ''
        },
        {
          id: 22,
          note: 'After the preamp, there are usually some knobs that adjust how the signal sounds. These are called EQ knobs, and they are used to shape the sound to the player’s preference.',
          imageURL: '/Graphics/FinalSlides/3_9_3.svg'
        },
        {
          id: 23,
          note: 'After the EQ, there is probably a knob that says master, volume, output, or a combination of these. That is the gain knob for the built-in power amp. Again, if it is turned up “too far” the signal distorts. Some people find this desirable in tube guitar amplifiers. Be careful, however, because this can damage your speaker.',
          imageURL: '/Graphics/FinalSlides/3_9_4.svg'
        },
        {
          id: 24,
          note: 'After the power amp, the signal comes out of the guitar amplifier and goes into the built-in or external speaker.',
          imageURL: '/Graphics/FinalSlides/3_9_5.svg'
        },
        {
          id: 25,
          note: 'This is fundamentally a miniaturized audio signal chain.',
          imageURL: '/Graphics/FinalSlides/3_9_6.svg'
        },
        {
          id: 26,
          note: 'Let\'s practice!',
          imageURL: ''
        }
      ]
    }
  },
  methods: {
    goToExercise () {
      this.$router.push('/Lesson3AExercise')
    }
  },
  mounted () {
    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 64px;
  /* max-width: 430px; */
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

p {
  font-size: 24px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  text-align: left;
}

img {
  /* max-width: 1055px; */
  max-width: 844px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.lesson-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: 430px; */
}

.lesson-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 48px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #008FFF;
  color: #ffffff;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: rgb(13, 123, 207);
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: rgb(22, 107, 172);
  color: white;
}

@media (max-width: 1068px) {

  h2 {
  font-size: 56px;
  /* max-width: 430px; */
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 21px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  img {
    /* max-width: 690px; */
    max-width: 552px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 735px) {

  h2 {
  font-size: 40px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 360px) {

  h2 {
  font-size: 40px;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

</style>
