<template>
  <div class="lesson-container">
    <div class="lesson-header-container">
      <h2>{{ lessonQuestion }}</h2>
    </div>
    <div id="lesson-content-container" v-for="(lessonNote, index) in lessonNotes" :key="index">
      <p>{{ lessonNote.note }}</p>
      <img :src="lessonNote.imageURL" alt="image" v-if="lessonNote.imageURL">
    </div>
    <div class="button-container">
      <div id="exercise-button">
        <button class="button" @click="goToExercise">Go To Exercise!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lesson-1-content',
  props: {
    msg: String
  },
  data () {
    return {
      lessonQuestion: 'What is audio signal flow?',
      lessonNotes: [
        {
          id: 1,
          note: 'Audio signal flow describes how an audio signal moves through any type of sound system.',
          imageURL: '/Graphics/FinalSlides/1_1.svg'
        },
        {
          id: 2,
          note: 'Signal flow can be thought of as a series of inputs and outputs.',
          imageURL: ''
        },
        {
          id: 3,
          note: 'Let’s look at a few simplified familiar examples',
          imageURL: ''
        },
        {
          id: 4,
          note: ']Listening on the phone:',
          imageURL: '/Graphics/FinalSlides/1_2_1.svg'
        },
        {
          id: 5,
          note: 'When you listen to music on your phone, the music or audio signal leaves your phone through your headphone jack or dongle (let’s ignore Bluetooth and other wireless technologies for now.) This is an output.',
          imageURL: '/Graphics/FinalSlides/1_2_2.svg'
        },
        {
          id: 6,
          note: 'The audio signal is carried along the headphone cable to the headphones. This is an input.',
          imageURL: '/Graphics/FinalSlides/1_2_3.svg'
        },
        {
          id: 7,
          note: 'Listening to music from your phone in the car:',
          imageURL: '/Graphics/FinalSlides/1_3_1.svg'
        },
        {
          id: 8,
          note: 'When you listen to music in your car from your phone. The audio signal leaves or is output from your phone through your headphone jack, dongle, or USB cable.',
          imageURL: '/Graphics/FinalSlides/1_3_2.svg'
        },
        {
          id: 9,
          note: 'The signal travels along the cable and is INPUT into your radio.',
          imageURL: '/Graphics/FinalSlides/1_3_3.svg'
        },
        {
          id: 10,
          note: 'The signal is output from your radio and is input into your speakers.',
          imageURL: '/Graphics/FinalSlides/1_3_4.svg'
        },
        {
          id: 11,
          note: 'We can even push the audio flow description to include the human element.',
          imageURL: '/Graphics/FinalSlides/1_4.svg'
        },
        {
          id: 12,
          note: 'When someone speaks to a crowd of people over a microphone, the signal flow looks like this:',
          imageURL: '/Graphics/FinalSlides/1_5_1.svg'
        },
        {
          id: 13,
          note: 'The words leave the person’s mouth as acoustical energy or vibrations in the air (output) The vibrations travel through the air and into the microphone (input)',
          imageURL: '/Graphics/FinalSlides/1_5_2.svg'
        },
        {
          id: 14,
          note: 'A microphone is what we call a transducer, which means it converts one form of energy to another. Mics convert acoustic energy to an electrical signal.',
          imageURL: '/Graphics/FinalSlides/1_5_3.svg'
        },
        {
          id: 15,
          note: 'The audio signal leaves the microphone as an electrical signal. (Output) It travels along the microphone cable to a soundboard or console. (input)',
          imageURL: '/Graphics/FinalSlides/1_5_4.svg'
        },
        {
          id: 16,
          note: 'This signal leaves the soundboard (output) and travels along a cable to the speakers (input)',
          imageURL: '/Graphics/FinalSlides/1_5_5.svg'
        },
        {
          id: 17,
          note: 'A speaker is another type of transducer. It is the opposite of a microphone, so it converts an electrical signal into acoustic energy.',
          imageURL: '/Graphics/FinalSlides/1_5_6.svg'
        },
        {
          id: 18,
          note: 'The acoustic energy leaves the speaker via vibrations in the air (output) The vibrations travel through the air and reach the listener’s ears. (input)',
          imageURL: '/Graphics/FinalSlides/1_5_7.svg'
        },
        {
          id: 19,
          note: 'An understanding of inputs and outputs is essential to learning signal flow. Let’s practice!',
          imageURL: ''
        }
      ]
    }
  },
  methods: {
    goToExercise () {
      this.$router.push('/Lesson1AExercise')
    }
  },
  mounted () {
    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 64px;
  /* max-width: 430px; */
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

p {
  font-size: 24px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  text-align: left;
}

img {
  /* max-width: 1055px; */
  max-width: 844px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.lesson-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: 430px; */
}

.lesson-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 48px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #008FFF;
  color: #ffffff;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: rgb(13, 123, 207);
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: rgb(22, 107, 172);
  color: white;
}

@media (max-width: 1068px) {

  h2 {
  font-size: 56px;
  /* max-width: 430px; */
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 21px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  img {
    /* max-width: 690px; */
    max-width: 552px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 735px) {

  h2 {
  font-size: 40px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 360px) {

  h2 {
  font-size: 40px;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

</style>
