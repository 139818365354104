<template>
  <div class="home">
    <certificateTemplate6 msg="certificateTemplate6"/>
  </div>
</template>

<script>
// @ is an alias to /src
import certificateTemplate6 from '@/components/certificateTemplate6.vue'

export default {
  name: 'certificate6',
  components: {
    certificateTemplate6
  }
}
</script>
