<template>
  <div class="home">
    <Lesson2BExercise msg="Exercise 2-B"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson2BExercise from '@/components/Lesson2BExercise.vue'

export default {
  name: 'Exercise2B',
  components: {
    Lesson2BExercise
  }
}
</script>
