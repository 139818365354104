<template>
  <div class="home">
    <Lesson5Content msg="Lesson 5"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson5Content from '@/components/Lesson5Content.vue'

export default {
  name: 'Lesson5',
  components: {
    Lesson5Content
  }
}
</script>
