<template>
  <div class="home">
    <Lesson6CExercise msg="Exercise 6-C"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson6CExercise from '@/components/Lesson6CExercise.vue'

export default {
  name: 'Exercise6C',
  components: {
    Lesson6CExercise
  }
}
</script>
