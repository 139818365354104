<template>
  <div class="home">
    <Lesson1DExercise msg="Exercise 1-D"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson1DExercise from '@/components/Lesson1DExercise.vue'

export default {
  name: 'Exercise1D',
  components: {
    Lesson1DExercise
  }
}
</script>
