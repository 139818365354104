<template>
  <div class="home">
    <Lesson6Content msg="Lesson 6"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson6Content from '@/components/Lesson6Content.vue'

export default {
  name: 'Lesson6',
  components: {
    Lesson6Content
  }
}
</script>
