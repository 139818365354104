<template>
  <div class="home">
    <Lesson1BExercise msg="Exercise 1-B"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson1BExercise from '@/components/Lesson1BExercise.vue'

export default {
  name: 'Exercise1B',
  components: {
    Lesson1BExercise
  }
}
</script>
