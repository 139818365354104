<template>
  <div class="lesson-container">
    <div class="lesson-header-container">
      <h2>{{ lessonQuestion }}</h2>
    </div>
    <div id="lesson-content-container" v-for="(lessonNote, index) in lessonNotes" :key="index">
      <img :src="lessonNote.imageURL" alt="image" v-if="lessonNote.imageURL">
      <p>{{ lessonNote.note }}</p>
    </div>
    <div class="button-container">
      <div id="exercise-button">
        <button class="button" @click="goToExercise">Go To Exercise!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lesson-5-content',
  props: {
    msg: String
  },
  data () {
    return {
      lessonQuestion: 'Simple Live Sound Setup',
      lessonNotes: [
        {
          id: 1,
          note: 'Now that we have a basic understanding of how a console works, let’s talk about how the signal flow of a simple live event would look.',
          imageURL: ''
        },
        {
          id: 2,
          note: 'This event has four sound sources: vocal, acoustic, keyboard, and djembe.',
          imageURL: '/Graphics/FinalSlides/5_1_1.svg'
        },
        {
          id: 3,
          note: 'We need to use transducers to turn all of these acoustic instruments into electrical signals.',
          imageURL: ''
        },
        {
          id: 4,
          note: 'We need a microphone for the vocal and djembe.',
          imageURL: '/Graphics/FinalSlides/5_1_2.svg'
        },
        {
          id: 5,
          note: 'The acoustic guitar has an onboard pickup, and the keyboard is an electrical instrument, so both of them send out an electrical signal. However, to get these signals to the console without interference, we need to use DI or Direct Input box.',
          imageURL: '/Graphics/FinalSlides/5_1_3.svg'
        },
        {
          id: 6,
          note: 'A DI is used to turn the electrical signal from unbalanced, which is susceptible to interference and signal loss, to a balanced signal, which can be sent over long distances over a microphone cable.',
          imageURL: ''
        },
        {
          id: 7,
          note: 'From there, each input would go into a different channel on the mixing console',
          imageURL: '/Graphics/FinalSlides/5_1_4.svg'
        },
        {
          id: 8,
          note: 'Each signal would go through its own channel strip and be sent to the master bus from each fader.',
          imageURL: '/Graphics/FinalSlides/5_1_5.svg'
        },
        {
          id: 9,
          note: 'As we know from the last lesson, the master fader controls the master output.',
          imageURL: '/Graphics/FinalSlides/5_1_6.svg'
        },
        {
          id: 10,
          note: 'The combined audio leaves the master output and goes to a power amplifier and then to the main speaker for the event. This speaker is what the audience hears.',
          imageURL: '/Graphics/FinalSlides/5_1_7.svg'
        },
        {
          id: 11,
          note: 'If that speaker is covering the audience, then what will the musicians hear? This is where the monitor speaker comes in, but how do we rout a signal to it? The musicians will not need to listen to the same thing as the audience.',
          imageURL: '/Graphics/FinalSlides/5_2_1.svg'
        },
        {
          id: 12,
          note: 'We need to use an Auxiliary Send for this purpose. An aux send is used to send signals from a channel to an auxiliary bus. There is a knob on each channel that is used to set how much of the signal is sent to the aux bus. This knob serves the same purpose for the aux bus as the channel fader does for the master bus.',
          imageURL: '/Graphics/FinalSlides/5_2_2.svg'
        },
        {
          id: 13,
          note: 'The aux bus, much like the master bus, combines the aux send of multiple channels together to be routed elsewhere.',
          imageURL: '/Graphics/FinalSlides/5_2_3.svg'
        },
        {
          id: 14,
          note: 'The aux master knob controls the aux output, just like the master fader controls the master output.',
          imageURL: '/Graphics/FinalSlides/5_2_4.svg'
        },
        {
          id: 15,
          note: 'The aux send signal can then be sent from the output into another power amp that then goes to the monitor speaker.',
          imageURL: '/Graphics/FinalSlides/5_2_5.svg'
        },
        {
          id: 16,
          note: 'Aux sends can come off a channel either before (pre-fader) or after the fader (post-fader). Pre-fader sends like Aux 1 on this console are good to use for monitor sends. Post-fader sends like Aux 2 on this console are good to use for effects because you want the send to turn down as you turn down the fader. Otherwise, you would have a reverb that doesn’t adjust with the channel volume in the mix.',
          imageURL: '/Graphics/FinalSlides/5_3.svg'
        },
        {
          id: 17,
          note: 'For this event, we want reverb on the vocal and acoustic guitar. So, we would need to turn up the Aux 2 send on each of those channels. This sends some of the post-fader signal of each channel to the Aux 2 bus and then to the Aux 2 output.',
          imageURL: '/Graphics/FinalSlides/5_4_1.svg'
        },
        {
          id: 18,
          note: 'The signal from the Aux 2 output is put into the effects unit.',
          imageURL: '/Graphics/FinalSlides/5_4_2.svg'
        },
        {
          id: 19,
          note: 'Now we have to get that affected signal back into the console so we can hear it. Some consoles have a dedicated input for this, but on this console, we will just come back into another channel.',
          imageURL: '/Graphics/FinalSlides/5_4_3.svg'
        },
        {
          id: 20,
          note: 'This channel can be used to shape and mix the reverb into the main mix, just like any other channel.',
          imageURL: '/Graphics/FinalSlides/5_4_4.svg'
        },
        {
          id: 21,
          note: 'This is a straightforward live setup, but if you can understand these concepts, you can do events that get much bigger than this.',
          imageURL: '/Graphics/FinalSlides/5_5.svg'
        },
        {
          id: 22,
          note: 'Let\'s Practice!',
          imageURL: ''
        }
      ]
    }
  },
  methods: {
    goToExercise () {
      this.$router.push('/Lesson5AExercise')
    }
  },
  mounted () {
    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 64px;
  /* max-width: 430px; */
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

p {
  font-size: 24px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  text-align: left;
}

img {
  /* max-width: 1055px; */
  max-width: 844px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.lesson-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: 430px; */
}

.lesson-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 48px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #008FFF;
  color: #ffffff;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: rgb(13, 123, 207);
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: rgb(22, 107, 172);
  color: white;
}

@media (max-width: 1068px) {

  h2 {
  font-size: 56px;
  /* max-width: 430px; */
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 21px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  img {
    /* max-width: 690px; */
    max-width: 552px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 735px) {

  h2 {
  font-size: 40px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 360px) {

  h2 {
  font-size: 40px;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

</style>
