<template>
  <div class="home">
    <notSupported msg="not supported page"/>
  </div>
</template>

<script>
// @ is an alias to /src
import notSupported from '@/components/notSupported.vue'

export default {
  name: 'notSupportedPage',
  components: {
    notSupported
  }
}
</script>
