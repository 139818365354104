<template>
  <div class="home">
    <Lesson5CExercise msg="Exercise 5-C"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson5CExercise from '@/components/Lesson5CExercise.vue'

export default {
  name: 'Exercise5C',
  components: {
    Lesson5CExercise
  }
}
</script>
