<template>
  <div id="all-lessons-container">
    <h2>All Lessons</h2>
    <div class="lesson-link-container">
      <router-link to="/Lesson1" class="link">
        <div class="lesson-link">
          Lesson 1: Inputs and Outputs
        </div>
      </router-link>
      <router-link to="/Lesson2" class="link">
        <div class="lesson-link">
          Lesson 2: The Levels of Audio
        </div>
      </router-link>
      <router-link to="/Lesson3" class="link">
        <div class="lesson-link">
          Lesson 3: The Preamp and Power Amplifier
        </div>
      </router-link>
      <router-link to="/Lesson4" class="link">
        <div class="lesson-link">
          Lesson 4: The Audio Console
        </div>
      </router-link>
      <router-link to="/Lesson5" class="link">
        <div class="lesson-link">
          Lesson 5: Simple Live Sound Setup
        </div>
      </router-link>
      <router-link to="/Lesson6" class="link">
        <div class="lesson-link">
          Lesson 6: Simple Recording Studio Setup
        </div>
      </router-link>
      <router-link to="/credits" class="link">
        <div class="lesson-link">
          Site Credits
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'allLessons',
  props: {
    msg: String
  },
  mounted () {
    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 3vw;
  margin: 0;
}

#all-lessons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
}

.lesson-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lesson-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  width: 640px;
  background-color: #FFFFFF;
  color: black;
  margin: 18px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 6px;
  font-size: 24px;
  font-family: 'Montserrat', 'Lato', sans-serif;
  font-weight: 600;
}

.lesson-link:hover {
  background-color: rgba(212, 212, 212, 0.932);
}

.lesson-link:active {
  background-color: rgb(192, 192, 192);
}

.link {
  text-decoration: none;
}

</style>
