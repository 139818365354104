<template>
  <div class="home">
    <Lesson5BExercise msg="Exercise 5-B"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson5BExercise from '@/components/Lesson5BExercise.vue'

export default {
  name: 'Exercise5B',
  components: {
    Lesson5BExercise
  }
}
</script>
