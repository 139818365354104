<template>
  <div class="lesson-container">
    <div class="lesson-header-container">
      <h2>{{ lessonQuestion }}</h2>
    </div>
    <div id="lesson-content-container" v-for="(lessonNote, index) in lessonNotes" :key="index">
      <img :src="lessonNote.imageURL" alt="image" v-if="lessonNote.imageURL">
      <p>{{ lessonNote.note }}</p>
    </div>
    <div class="button-container">
      <div id="exercise-button">
        <button class="button" @click="goToExercise">Go To Exercise!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lesson-4-content',
  props: {
    msg: String
  },
  data () {
    return {
      lessonQuestion: 'The Audio Console',
      lessonNotes: [
        {
          id: 1,
          note: 'The audio console is perhaps the most critical piece of equipment in professional audio both at live events and in recording studios.',
          imageURL: '/Graphics/FinalSlides/4_1_1.svg'
        },
        {
          id: 2,
          note: 'An audio console, soundboard, or mixer is used to take various audio signals and mix them together and route them to their next destination in the audio signal chain.',
          imageURL: '/Graphics/FinalSlides/4_1_2.svg'
        },
        {
          id: 3,
          note: 'For this lesson, we will focus on how an audio console is used at a live concert.',
          imageURL: ''
        },
        {
          id: 4,
          note: 'While an audio console can have anywhere from just a few to over a hundred channels on it, each channel is essentially the same. You need different channels because each audio signal needs different treatment.',
          imageURL: '/Graphics/FinalSlides/4_2.svg'
        },
        {
          id: 5,
          note: 'Every microphone, instrument, and sound source can be routed through the audio console. We will focus on just one input right now.',
          imageURL: ''
        },
        {
          id: 6,
          note: 'A microphone will send its signal out through a mic cable and be run into a microphone input on the console.',
          imageURL: '/Graphics/FinalSlides/4_3_1.svg'
        },
        {
          id: 7,
          note: 'That microphone input goes into the preamp inside the corresponding channel on the console. There is a gain knob on the channel that is used to adjust the preamp gain and bring the mic signal up to line level.',
          imageURL: '/Graphics/FinalSlides/4_3_2.svg'
        },
        {
          id: 8,
          note: 'After the signal is boosted to line level, the signal is ready to be shaped and adjusted by EQ.',
          imageURL: '/Graphics/FinalSlides/4_3_3.svg'
        },
        {
          id: 9,
          note: 'The EQ on the console is a lot like the “sound” adjustments on a car studio. It can be used to turn up or down the bass, mids, and treble of that channel of the console. Except on the console, they are called lows, mids, and highs, and there may even be more than three of them!',
          imageURL: ''
        },
        {
          id: 10,
          note: 'After the signal is strong enough and shaped by EQ to sound pleasing, it moves on to the output of that channel.',
          imageURL: '/Graphics/FinalSlides/4_3_4.svg'
        },
        {
          id: 11,
          note: 'This output is usually controlled by a fader, which is just a slider that adjusts gain. This fader is comparable to the preamp gain at the beginning of the channel, except this adjusts the output gain and is usually used to turn down the output instead of boosting it.',
          imageURL: ''
        },
        {
          id: 12,
          note: 'The signal is routed from the fader to the main bus of the audio console. A bus is a channel on the console that can accept multiple mixed outputs and sum them together to send to an output of the console.',
          imageURL: '/Graphics/FinalSlides/4_3_5.svg'
        },
        {
          id: 13,
          note: 'The main bus takes all of the signals that are being sent to it, combines them together, and puts them through the master fader to set the level of the main output of the console.',
          imageURL: '/Graphics/FinalSlides/4_3_6.svg'
        },
        {
          id: 14,
          note: 'Sometimes, an audio engineer might want to add an external piece of equipment to the signal chain of a console. An insert is usually used to accomplish this.',
          imageURL: '/Graphics/FinalSlides/4_3_7.svg'
        },
        {
          id: 15,
          note: 'The insert is usually just after the preamp in the signal chain. It allows the preamp output to be sent to outboard equipment and put back into the channel strip just before the EQ.',
          imageURL: '/Graphics/FinalSlides/4_3_8.svg'
        },
        {
          id: 16,
          note: 'This is usually where equipment we call dynamics processors are added into the chain. Dynamics processors are used to adjust the dynamic range of an audio signal. Dynamic range is just the difference between the loudest and quietest part of an audio signal.',
          imageURL: '/Graphics/FinalSlides/4_3_9_1.svg'
        },
        {
          id: 17,
          note: 'Common dynamic processors are compressors, limiters, expanders, and noise gates.',
          imageURL: ''
        },
        {
          id: 18,
          note: 'The insert of the console can sometimes be used to bypass the preamp of the channel when using an outboard preamp.',
          imageURL: '/Graphics/FinalSlides/4_3_9_2.svg'
        },
        {
          id: 19,
          note: 'Let\'s Practice!',
          imageURL: ''
        }
      ]
    }
  },
  methods: {
    goToExercise () {
      this.$router.push('/Lesson4AExercise')
    }
  },
  mounted () {
    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 64px;
  /* max-width: 430px; */
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

p {
  font-size: 24px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  text-align: left;
}

img {
  /* max-width: 1055px; */
  max-width: 844px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.lesson-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: 430px; */
}

.lesson-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 48px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #008FFF;
  color: #ffffff;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: rgb(13, 123, 207);
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: rgb(22, 107, 172);
  color: white;
}

@media (max-width: 1068px) {

  h2 {
  font-size: 56px;
  /* max-width: 430px; */
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 21px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  img {
    /* max-width: 690px; */
    max-width: 552px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 735px) {

  h2 {
  font-size: 40px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 360px) {

  h2 {
  font-size: 40px;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

</style>
