<template>
  <div id="exercise-1-container">
    <!-- <p>Child element count test: {{ connectionsCount }} </p> -->
    <div class="lesson-intro-container">
      <div class="page-header-container">
        <div class="header-container">
          <h2>{{ exercisePageHeader }} {{ exercisePart }}</h2>
        </div>
      </div>
      <div class="lesson-instructions-container">
        <p> {{ lessonInstructions }} </p>
        <p> {{ lessonPartText }} </p>
        <div class="hint-container">
          <h3 class="hint-header">Hints:</h3>
          <p v-for="(hint, index) in hints" :key="index" class="hints"> {{ hint }} </p>
        </div>
      </div>
    </div>
    <div class="draggable-card-parent-container">
      <fieldset>
        <legend>Connections</legend>
        <div id="draggable-card-container-connections" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
          <draggable-card v-for="(content, index) in connectionCardDatas" :content="content" :key="index" draggable="true"/>
        </div>
      </fieldset>
    </div>
    <!-- Had problems with making a target component, do it stupidly with v-ifs for now, make component work later-->
    <div v-if="lessonPartIndex==0" id="drop-off-container" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
      <draggable-card class="un-dragged" :content="elementCardDatas[0]" draggable="true"/>
      <div class='target in-shadow' id="targ1" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <draggable-card class="un-dragged" :content="elementCardDatas[1]" draggable="true"/>
      <div class='target in-shadow' id="targ2" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <draggable-card class="un-dragged" :content="elementCardDatas[2]" draggable="true"/>
      <div class='target in-shadow' id="targ3" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <draggable-card class="un-dragged" :content="elementCardDatas[3]" draggable="true"/>
      <div class='target in-shadow' id="targ4" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <draggable-card class="un-dragged" :content="elementCardDatas[4]" draggable="true"/>
      <div class='target in-shadow' id="targ5" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <draggable-card class="un-dragged" :content="elementCardDatas[5]" draggable="true"/>
    </div>
    <div class="button-container">
      <div id="test">
        <button class="button gotonext" @click="goToPrev">Prev Lesson</button>
      </div>
      <div id="test">
        <button class="button" @click="submitAnswer">submit</button>
      </div>
      <div class="reset-button-container">
        <button class="button reset" @click="resetPage">start over</button>
      </div>
      <div v-show="partComplete">
      <button class="button gotonext" @click="goToNext">Next Lesson</button>
    </div>
    </div>
  </div>
</template>

<script>
import draggableCard from './draggableCard.vue'
// import { mapState } from 'vuex'

export default {
  name: 'lesson-2-b-exercise',
  props: {
    msg: String
  },
  components: {
    draggableCard
  },
  data () {
    return {
      exercisePageHeader: 'Exercise 2 - ',
      lessonInstructions: 'Drag the correct audio type and level between each element.',
      lessonPartText: 'What if we extend this to add the human element?',
      lessonPartIndex: 0,
      totalLessonParts: 4,
      draggedCard: '',
      orderCheck: 0,
      partComplete: 0,
      winAudio: '/sounds/Win.mp3',
      loseAudio: '/sounds/Lose.mp3',
      buttonClickAudio: '/sounds/Click1.mp3',
      dropAudio: '/sounds/Click2.mp3',
      bedLoopAudio: '/sounds/Bed1.mp3',
      connectionsCount: '',
      userAnswer: [],
      exercisePart: 'B',
      exerciseDescription: 'This is what\'s up.',
      answerOrder: ['Acoustic', 'Mic Level', 'Line Level', 'Speaker Level', 'Acoustic'],
      hints: [
        'Sound moves through the air as vibrations. We are calling these vibrations acoustic signals.',
        'The Preamp brings an audio signal up to line level.',
        'The Power Amp brings an audio signal up to speaker level.'
      ],
      connectionCardDatas: [
        {
          name: 'Acoustic',
          image: '/Graphics/Icons/Wave.svg',
          type: 'connection',
          id: 'acousticconnection-card'
        },
        {
          name: 'Mic Level',
          image: '/Graphics/Icons/Wave_Sm.svg',
          type: 'connection',
          id: 'miclevel-card'
        },
        {
          name: 'Line Level',
          image: '/Graphics/Icons/Wave_Md.svg',
          type: 'connection',
          id: 'linelevel-card'
        },
        {
          name: 'Speaker Level',
          image: '/Graphics/Icons/Wave_Lg.svg',
          type: 'connection',
          id: 'speakerlevel-card'
        }
      ],
      elementCardDatas: [
        {
          name: 'Mouth',
          image: '/Graphics/Icons/Avatar Vocal.svg',
          type: 'element',
          id: 'mouth-card'
        },
        {
          name: 'Mic',
          image: '/Graphics/Icons/Mic.svg',
          type: 'element',
          id: 'mic-card'
        },
        {
          name: 'Preamp',
          image: '/Graphics/Icons/PreAmp.svg',
          type: 'element',
          id: 'preamp-card'
        },
        {
          name: 'Power Amp',
          image: '/Graphics/Icons/PowerAmp.svg',
          type: 'element',
          id: 'poweramp-card'
        },
        {
          name: 'Speaker',
          image: '/Graphics/Icons/Speaker.svg',
          type: 'element',
          id: 'speaker-card'
        },
        {
          name: 'Listener',
          image: '/Graphics/Icons/Avatar.svg',
          type: 'element',
          id: 'listener-card'
        }
      ]
    }
  },
  // computed: mapState(['state']),
  methods: {
    // addElectricConnection () {
    //   this.connectionCardDatas.push({ // unshift instead of push, but bug in WATCH gets two acoustics
    //     name: 'Electric',
    //     image: '/Graphics/Icons/Wire1.svg',
    //     type: 'connection'
    //   })
    // },
    addAcousticConnection () {
      this.connectionCardDatas.push({
        name: 'Acoustic',
        image: '/Graphics/Icons/Wave.svg',
        type: 'connection',
        id: 'acousticconnection-card'
      })
    },
    addMicLevel () {
      this.connectionCardDatas.push({
        name: 'Mic Level',
        image: '/Graphics/Icons/Wave_Sm.svg',
        type: 'connection',
        id: 'miclevel-card'
      })
    },
    addLineLevel () {
      this.connectionCardDatas.push({
        name: 'Line Level',
        image: '/Graphics/Icons/Wave_Md.svg',
        type: 'connection',
        id: 'linelevel-card'
      })
    },
    addSpeakerLevel () {
      this.connectionCardDatas.push({
        name: 'Speaker Level',
        image: '/Graphics/Icons/Wave_Lg.svg',
        type: 'connection',
        id: 'speakerlevel-card'
      })
    },
    startOfDrag (e) {
      this.draggedCard = e.target
    },
    onDragOver (e) {
      e.preventDefault()
      e.target.style.borderColor = 'red'
    },
    onDragLeave (e) {
      e.preventDefault()
      e.target.style.borderColor = 'black'
    },
    onDragDrop (e) {
      if (e.target.classList.contains('target')) {
        e.target.appendChild(this.draggedCard)
        e.target.style.borderColor = 'blue'
      }
      this.connectionsCount = document.getElementById('draggable-card-container-connections').childElementCount
      this.playSoundAsync(this.dropAudio)
    },
    submitAnswer () {
      this.playSoundAsync(this.buttonClickAudio)
      // clear array
      this.userAnswer = []
      // check 1
      var par1 = document.getElementById('targ1')
      if (par1.childNodes.length === 1) {
        var chi1 = par1.childNodes[0]
        this.userAnswer.push(chi1.id)
      } else {
        alert('Spot #1 empty')
      }
      // check 2
      var par2 = document.getElementById('targ2')
      if (par2.childNodes.length === 1) {
        var chi2 = par2.childNodes[0]
        this.userAnswer.push(chi2.id)
      } else {
        alert('Spot #2 empty')
      }
      // check 3
      var par3 = document.getElementById('targ3')
      if (par3.childNodes.length === 1) {
        var chi3 = par3.childNodes[0]
        this.userAnswer.push(chi3.id)
      } else {
        alert('Spot #3 empty')
      }
      // check 4
      var par4 = document.getElementById('targ4')
      if (par4.childNodes.length === 1) {
        var chi4 = par4.childNodes[0]
        this.userAnswer.push(chi4.id)
      } else {
        alert('Spot #4 empty')
      }
      // check 5
      var par5 = document.getElementById('targ5')
      if (par5.childNodes.length === 1) {
        var chi5 = par5.childNodes[0]
        this.userAnswer.push(chi5.id)
      } else {
        alert('Spot #5 empty')
      }
      // check answer
      console.log(JSON.stringify(this.answerOrder))
      console.log(JSON.stringify(this.userAnswer))
      if (this.userAnswer.length !== this.answerOrder.length) {
        alert('Hm. You may have forgotten something. Check your answer again!')
      } else {
        if (JSON.stringify(this.answerOrder) === JSON.stringify(this.userAnswer)) {
          this.partComplete = 1
          // console.log('pre state change: ex1ptA = ' + this.$store.getters.ex1ptA)
          this.$store.commit('ex2ptBcomplete')
          // console.log('post state change: ex1ptA = ' + this.$store.getters.ex1ptA)
          alert('Correct! Great job!')
          this.$store.commit('loopStop')
          this.playSoundAsync(this.winAudio)
          // this.$store.commit('loopPlay')
        } else {
          alert('Wrong! Try again.')
          this.$store.commit('loopStop')
          this.playSoundAsync(this.loseAudio)
          // this.$store.commit('loopPlay')
        }
      }
    },
    goToNext () {
      this.playSoundAsync(this.buttonClickAudio)
      if (this.partComplete === 1) {
        this.$router.push('/Lesson2CExercise')
      }
    },
    goToPrev () {
      this.playSoundAsync(this.buttonClickAudio)
      this.$router.push('/Lesson2AExercise')
    },
    resetPage () {
      this.playSoundAsync(this.buttonClickAudio)
      location.reload()
    },
    playSoundAsync (url) {
      new Audio(url).play()
    },
    loopStop () {
      this.bedLoop.pause()
    }
  },
  mounted () {
    this.bedLoop = new Audio(this.bedLoopAudio) // .play()
    this.bedLoop.play()

    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
    for (var cardOption in this.elementCardOptions) {
      if (this.exerciseContent[this.lessonPartIndex].answerOrder.includes(this.elementCardOptions[cardOption].name)) {
        this.exerciseContent[this.lessonPartIndex].elementCardDatas.push(this.elementCardOptions[cardOption])
      }
    }

    this.connectionsCount = document.getElementById('draggable-card-container-connections').childElementCount
  },
  watch: {
    connectionsCount: function () { // check which connection was dropped and add another
      if (this.connectionsCount === 3) {
        let connectionsArr = []
        connectionsArr.push(document.getElementById('draggable-card-container-connections').childNodes[0].id)
        connectionsArr.push(document.getElementById('draggable-card-container-connections').childNodes[1].id)
        connectionsArr.push(document.getElementById('draggable-card-container-connections').childNodes[2].id)
        // console.log('Current Connections: ' + currentChild1 + ' and ' + currentChild2)
        if (connectionsArr.includes('Acoustic') && connectionsArr.includes('Mic Level') && connectionsArr.includes('Line Level')) {
          this.addSpeakerLevel()
        } else if (connectionsArr.includes('Acoustic') && connectionsArr.includes('Mic Level') && connectionsArr.includes('Speaker Level')) {
          this.addLineLevel()
        } else if (connectionsArr.includes('Acoustic') && connectionsArr.includes('Line Level') && connectionsArr.includes('Speaker Level')) {
          this.addMicLevel()
        } else if (connectionsArr.includes('Mic Level') && connectionsArr.includes('Line Level') && connectionsArr.includes('Speaker Level')) {
          this.addAcousticConnection()
        }
      }
    }
  },
  beforeDestroy () {
    this.bedLoop.pause()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 3.7vw;
  margin: 0;
}

.hint-container {
  margin-bottom: 12px;
}

.hint-header {
  margin: 0;
}

.hints {
  margin: 0;
}

.page-header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid black; */
  /* border-radius: 4px; */
  /* width: 40%; */
  /* height: 40px; */
}

.lesson-selection-button {
  margin: 14px 0px;
  width: 80px;
  height: 100%;
  /* border: 2px solid black; */
  /* border-radius: 6px; */
  border: none;
  letter-spacing: 4.72px;
  background-color: transparent;
  color: #080808;
  font-size: 18px;
  outline: none;
}

.lesson-selection-button:hover {
  background-color: #B3B3B3;
}

.lesson-selection-button:active {
  background-color: darkgray;
}

fieldset {
  margin: 10px;
}

.un-dragged {
  margin: 10px;
  padding: 0px;
}

.target {
  position: relative;
  display: inline-block;
  /* width: 175px; */
  /* height: 220px; */
  height: 180px;
  width: 120px;
  margin: 10px;
  padding: 0px;
  /* border: 1px dashed black; */
  border-radius: 6px;
  background-color: #E7E7E7;
}

.target h1 {
  font-size: 84px;
  color: #D2D2D2;
}

.target .draggable-card {
  position: absolute;
  left: 0px;
  top: 0px;
  /* z-index: 2; */
}

.draggable-card-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 300px; */
  height: 260px;
}

#exercise-1-container {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

#draggable-card-container-levels {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 10px; */
}

#draggable-card-container-levels .draggable-card {
  margin: 10px;
}

#draggable-card-container-connections {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 10px; */
}

#draggable-card-container-connections .draggable-card {
  margin: 10px;
}

#drop-off-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 40px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #ffffff;
  color: #080808;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: #008FFF;
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: #0073CD;
  color: white;
}

.button.reset:hover {
  background-color: #FF3333;
  color: white;
  cursor: pointer;
}

.button.reset:active {
  background-color: #CA1F1F;
  color: white;
}

.in-shadow {
   -moz-box-shadow:    inset 0 0 10px #B3B3B3;
   -webkit-box-shadow: inset 0 0 10px #B3B3B3;
   box-shadow:         inset 0 0 10px #B3B3B3;
}

@media (max-width: 1600px) {

  .target {
    width: 100px;
  }

}

</style>
