<template>
  <div class="not-supported-page-container">
    <div class="not-supported-message-container">
      <p v-if="currentUserAgent=='Firefox'" >
        It looks like you're visting our site using Firefox, unfortunately we do not yet support Firefox. We are working hard on it though! For now, please another browser like Chrome or Safari! Thanks!
      </p>
      <p v-if="currentUserAgent=='Mobile'" >
        It looks like you're visiting our site from a mobile device, unfortunately we do not support mobile devices yet. We are working hard on it though! For now, please hop on a laptop or desktop and use Chrome or Safari! Thanks!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notSupported',
  props: {
    msg: String
  },
  data () {
    return {
      currentUserAgent: ''
    }
  },
  methods: {
  },
  mounted () {
    if (navigator.userAgent.match(/Firefox/i)) {
      this.currentUserAgent = 'Firefox'
    } else if (navigator.userAgent.match(/Mobile/i)) {
      this.currentUserAgent = 'Mobile'
    }
  }
}
</script>

<style scoped>

.not-supported-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.not-supported-message-container {
  margin-top: 120px;
}

</style>
