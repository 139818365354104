
<template>
  <div id="exercise-1-container">
    <!-- <p>Child element count test: {{ connectionsCount }} </p> -->
    <div class="lesson-intro-container">
      <div class="page-header-container">
        <div class="header-container">
          <h2>{{ exercisePageHeader }} {{ exercisePart }}</h2>
        </div>
      </div>
      <div class="lesson-instructions-container">
        <p> {{ lessonInstructions }}</p>
        <div class="hint-container">
          <h3 class="hint-header">Hints:</h3>
          <p v-for="(hint, index) in hints" :key="index" class="hints"> {{ hint }} </p>
        </div>
      </div>
    </div>
    <div class="draggable-card-parent-container">
      <fieldset>
        <legend>Element/Line Outputs</legend>
        <div id="draggable-card-container-elements" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
          <draggable-card v-for="(content, index) in elementCardDatas" :content="content" :lessonPartIndex="lessonPartIndex" :key="index" draggable="true"/>
        </div>
      </fieldset>
      <!-- <fieldset>
        <legend>Connections</legend>
        <div id="draggable-card-container-connections" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
          <draggable-card v-for="(content, index) in connectionCardDatas" :content="content" :key="index" draggable="true"/>
        </div>
      </fieldset> -->
    </div>
    <!-- Had problems with making a target component, do it stupidly with v-ifs for now, make component work later-->
    <div v-if="lessonPartIndex==0" id="drop-off-container" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
      <draggable-card class="un-dragged" :content="prePlacedCards[1]" draggable="true"/>
      <div class='target in-shadow' id="targ1" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ2" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ3" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ4" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ5" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ6" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ7" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <draggable-card class="un-dragged" :content="prePlacedCards[2]" draggable="true"/>
      <div class='target in-shadow' id="targ8" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ9" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ10" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ11" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ12" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ13" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ14" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <draggable-card class="un-dragged" :content="prePlacedCards[0]" draggable="true"/>
    </div>
    <div class="button-container">
      <div id="test">
        <button class="button gotonext" @click="goToPrev">Prev Lesson</button>
      </div>
      <div id="test">
        <button class="button" @click="submitAnswer">submit</button>
      </div>
      <div class="reset-button-container">
        <button class="button reset" @click="resetPage">start over</button>
      </div>
      <div v-show="certAvailable" class="reset-button-container">
        <button class="button gotonext" @click="goToCert">Get Certificate</button>
      </div>
    </div>
  </div>
</template>

<script>
import draggableCard from './draggableCard.vue'
// import { mapState } from 'vuex'

export default {
  name: 'lesson-4-c-exercise',
  props: {
    msg: String
  },
  components: {
    draggableCard
  },
  data () {
    return {
      exercisePageHeader: 'Exercise 5 - ',
      lessonInstructions: 'This is a tough one. Let’s follow the vocal reverb from its beginning, with the vocal signal on Channel 1, all the way back through the console, on channel 5, to the audience. Remember that all effects sends should be post-fader.',
      lessonPartIndex: 0,
      totalLessonParts: 4,
      draggedCard: '',
      orderCheck: 0,
      certAvailable: 0,
      winAudio: '/sounds/Win.mp3',
      loseAudio: '/sounds/Lose.mp3',
      buttonClickAudio: '/sounds/Click1.mp3',
      dropAudio: '/sounds/Click2.mp3',
      bedLoopAudio: '/sounds/Bed1.mp3',
      connectionsCount: '',
      userAnswer: [],
      exercisePart: 'C',
      exerciseDescription: 'This is what\'s up.',
      answerOrder: ['Mic', 'Ch.1 Preamp', 'Ch.1 EQ', 'Ch.1 Fader', 'Aux 2 Send', 'Aux 2 Master', 'Aux 2 Output', 'Ch.5 Preamp', 'Ch.5 EQ', 'Ch.5 Fader', 'Master Fader', 'Master Output', 'Power Amp', 'Speaker'],
      hints: [
        'Post-fader sends are the best kind of aux send to use for effects units.',
        'The signal coming out of the effects unit must be put back into the console through another preamp. In this case, use channel 5.',
        'Remember, we are following the reverb signal, so the signal will not go to the master section until it has been completely processed and put back through another channel strip on the console.'
      ],
      prePlacedCards: [
        {
          name: 'Audience',
          image: '/Graphics/Icons/Avatar.svg',
          type: 'element',
          id: 'audience-card'
        },
        {
          name: 'Vocal',
          image: '/Graphics/FinalIcons/Avatar Vocal.svg',
          type: 'element',
          id: 'vocal-card'
        },
        {
          name: 'FX Unit',
          image: '/Graphics/FinalIcons/FXunit.svg',
          type: 'element',
          id: 'fxunit-card'
        }
      ],
      connectionCardDatas: [
        {
          name: 'Acoustic',
          image: '/Graphics/Icons/Wave.svg',
          type: 'connection',
          id: 'acousticconnection-card'
        }
      ],
      elementCardDatas: [
        {
          name: 'Aux 2 Send',
          image: '/Graphics/FinalIcons/AuxKnob.svg',
          type: 'element',
          id: 'aux-card'
        },
        {
          name: 'Master Output',
          image: '/Graphics/FinalIcons/XLRout.svg',
          type: 'element',
          id: 'xlr-card'
        },
        {
          name: 'Aux 2 Master',
          image: '/Graphics/FinalIcons/AuxKnob.svg',
          type: 'element',
          id: 'aux-card'
        },
        {
          name: 'Ch.1 Preamp',
          image: '/Graphics/Icons/PreAmp.svg',
          type: 'element',
          id: 'preamp-card'
        },
        {
          name: 'Ch.5 Fader',
          image: '/Graphics/FinalIcons/Fader.svg',
          type: 'element',
          id: 'fader-card'
        },
        {
          name: 'Ch.5 Preamp',
          image: '/Graphics/Icons/PreAmp.svg',
          type: 'element',
          id: 'preamp-card'
        },
        {
          name: 'Aux 2 Output',
          image: '/Graphics/FinalIcons/QuaterInchJack.svg',
          type: 'element',
          id: 'auxoutput-card'
        },
        {
          name: 'Speaker',
          image: '/Graphics/Icons/Speaker.svg',
          type: 'element',
          id: 'speaker-card'
        },
        {
          name: 'Ch.1 EQ',
          image: '/Graphics/FinalIcons/EQ.svg',
          type: 'element',
          id: 'speaker-card'
        },
        {
          name: 'Mic',
          image: '/Graphics/FinalIcons/Mic.svg',
          type: 'element',
          id: 'mic-card'
        },
        {
          name: 'Ch.1 Fader',
          image: '/Graphics/FinalIcons/Fader.svg',
          type: 'element',
          id: 'fader-card'
        },
        {
          name: 'Power Amp',
          image: '/Graphics/Icons/PowerAmp.svg',
          type: 'element',
          id: 'poweramp-card'
        },
        {
          name: 'Master Fader',
          image: '/Graphics/FinalIcons/Fader.svg',
          type: 'element',
          id: 'fader-card'
        },
        {
          name: 'Ch.5 EQ',
          image: '/Graphics/FinalIcons/EQ.svg',
          type: 'element',
          id: 'speaker-card'
        }
      ]
    }
  },
  // computed: mapState(['state']),
  methods: {
    // addAcousticConnection () {
    //   this.connectionCardDatas.push({
    //     name: 'Acoustic',
    //     image: '/Graphics/Icons/Wave.svg',
    //     type: 'connection'
    //   })
    // },
    startOfDrag (e) {
      this.draggedCard = e.target
    },
    onDragOver (e) {
      e.preventDefault()
      e.target.style.borderColor = 'red'
    },
    onDragLeave (e) {
      e.preventDefault()
      e.target.style.borderColor = 'black'
    },
    onDragDrop (e) {
      if (e.target.classList.contains('target')) {
        e.target.appendChild(this.draggedCard)
        e.target.style.borderColor = 'blue'
      }
      // this.connectionsCount = document.getElementById('draggable-card-container-connections').childElementCount
      this.playSoundAsync(this.dropAudio)
    },
    submitAnswer () {
      this.playSoundAsync(this.buttonClickAudio)
      // clear array
      this.userAnswer = []
      // check 1
      var par1 = document.getElementById('targ1')
      if (par1.childNodes.length === 1) {
        var chi1 = par1.childNodes[0]
        this.userAnswer.push(chi1.id)
      } else {
        alert('Spot #1 empty')
      }
      // check 2
      var par2 = document.getElementById('targ2')
      if (par2.childNodes.length === 1) {
        var chi2 = par2.childNodes[0]
        this.userAnswer.push(chi2.id)
      } else {
        alert('Spot #2 empty')
      }
      // check 3
      var par3 = document.getElementById('targ3')
      if (par3.childNodes.length === 1) {
        var chi3 = par3.childNodes[0]
        this.userAnswer.push(chi3.id)
      } else {
        alert('Spot #3 empty')
      }
      // check 4
      var par4 = document.getElementById('targ4')
      if (par4.childNodes.length === 1) {
        var chi4 = par4.childNodes[0]
        this.userAnswer.push(chi4.id)
      } else {
        alert('Spot #4 empty')
      }
      // check 5
      var par5 = document.getElementById('targ5')
      if (par5.childNodes.length === 1) {
        var chi5 = par5.childNodes[0]
        this.userAnswer.push(chi5.id)
      } else {
        alert('Spot #5 empty')
      }
      // check 6
      var par6 = document.getElementById('targ6')
      if (par6.childNodes.length === 1) {
        var chi6 = par6.childNodes[0]
        this.userAnswer.push(chi6.id)
      } else {
        alert('Spot #6 empty')
      }
      // check 5
      var par7 = document.getElementById('targ7')
      if (par7.childNodes.length === 1) {
        var chi7 = par7.childNodes[0]
        this.userAnswer.push(chi7.id)
      } else {
        alert('Spot #7 empty')
      }
      // check 5
      var par8 = document.getElementById('targ8')
      if (par8.childNodes.length === 1) {
        var chi8 = par8.childNodes[0]
        this.userAnswer.push(chi8.id)
      } else {
        alert('Spot #8 empty')
      }
      // check 5
      var par9 = document.getElementById('targ9')
      if (par9.childNodes.length === 1) {
        var chi9 = par9.childNodes[0]
        this.userAnswer.push(chi9.id)
      } else {
        alert('Spot #9 empty')
      }
      // check 5
      var par10 = document.getElementById('targ10')
      if (par10.childNodes.length === 1) {
        var chi10 = par10.childNodes[0]
        this.userAnswer.push(chi10.id)
      } else {
        alert('Spot #10 empty')
      }
      // check 5
      var par11 = document.getElementById('targ11')
      if (par11.childNodes.length === 1) {
        var chi11 = par11.childNodes[0]
        this.userAnswer.push(chi11.id)
      } else {
        alert('Spot #11 empty')
      }
      // check 5
      var par12 = document.getElementById('targ12')
      if (par12.childNodes.length === 1) {
        var chi12 = par12.childNodes[0]
        this.userAnswer.push(chi12.id)
      } else {
        alert('Spot #12 empty')
      }
      // check 5
      var par13 = document.getElementById('targ13')
      if (par13.childNodes.length === 1) {
        var chi13 = par13.childNodes[0]
        this.userAnswer.push(chi13.id)
      } else {
        alert('Spot #13 empty')
      }
      // check 5
      var par14 = document.getElementById('targ14')
      if (par14.childNodes.length === 1) {
        var chi14 = par14.childNodes[0]
        this.userAnswer.push(chi14.id)
      } else {
        alert('Spot #14 empty')
      }
      // check answer
      console.log(JSON.stringify(this.answerOrder))
      console.log(JSON.stringify(this.userAnswer))
      if (this.userAnswer.length !== this.answerOrder.length) {
        alert('Hm. You may have forgotten something. Check your answer again!')
      } else {
        if (JSON.stringify(this.answerOrder) === JSON.stringify(this.userAnswer)) {
          this.$store.commit('ex5ptCcomplete')
          if (this.$store.getters.ex5ptA === 1 && this.$store.getters.ex5ptB === 1 && this.$store.getters.ex5ptC === 1) {
            this.certAvailable = 1
            console.log('Certificate now available')
          }
          alert('Correct! Great job!')
          this.$store.commit('loopStop')
          this.playSoundAsync(this.winAudio)
          // this.$store.commit('loopPlay')
        } else {
          alert('Wrong! Try again.')
          this.$store.commit('loopStop')
          this.playSoundAsync(this.loseAudio)
          // this.$store.commit('loopPlay')
        }
      }
    },
    goToCert () {
      this.playSoundAsync(this.buttonClickAudio)
      if (this.certAvailable === 1) {
        // this.$router.push('/Lesson1DExercise')
        this.$router.push('/certificate5')
      }
    },
    goToPrev () {
      this.playSoundAsync(this.buttonClickAudio)
      this.$router.push('/Lesson5BExercise')
    },
    resetPage () {
      this.playSoundAsync(this.buttonClickAudio)
      location.reload()
    },
    playSoundAsync (url) {
      new Audio(url).play()
    },
    loopStop () {
      this.bedLoop.pause()
    }
  },
  mounted () {
    this.bedLoop = new Audio(this.bedLoopAudio) // .play()
    this.bedLoop.play()

    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
    for (var cardOption in this.elementCardOptions) {
      if (this.exerciseContent[this.lessonPartIndex].answerOrder.includes(this.elementCardOptions[cardOption].name)) {
        this.exerciseContent[this.lessonPartIndex].elementCardDatas.push(this.elementCardOptions[cardOption])
      }
    }

    // this.connectionsCount = document.getElementById('draggable-card-container-connections').childElementCount
  },
  watch: {
    // connectionsCount: function () { // check which connection was dropped and add another
    //   if (this.connectionsCount === 0) {
    //     this.addAcousticConnection()
    //   }
    // }
  },
  beforeDestroy () {
    this.bedLoop.pause()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 3.7vw;
  margin: 0;
}

.hint-container {
  margin-bottom: 12px;
}

.hint-header {
  margin: 0;
}

.hints {
  margin: 0;
}

.page-header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid black; */
  /* border-radius: 4px; */
  /* width: 40%; */
  /* height: 40px; */
}

.lesson-selection-button {
  margin: 14px 0px;
  width: 80px;
  height: 100%;
  /* border: 2px solid black; */
  /* border-radius: 6px; */
  border: none;
  letter-spacing: 4.72px;
  background-color: transparent;
  color: #080808;
  font-size: 18px;
  outline: none;
}

.lesson-selection-button:hover {
  background-color: #B3B3B3;
}

.lesson-selection-button:active {
  background-color: darkgray;
}

fieldset {
  margin: 10px;
}

.un-dragged {
  margin: 10px;
  padding: 0px;
}

.target {
  position: relative;
  display: inline-block;
  /* width: 175px; */
  /* height: 220px; */
  height: 180px;
  width: 120px;
  margin: 10px;
  padding: 0px;
  /* border: 1px dashed black; */
  border-radius: 6px;
  background-color: #E7E7E7;
}

.target h1 {
  font-size: 84px;
  color: #D2D2D2;
}

.target .draggable-card {
  position: absolute;
  left: 0px;
  top: 0px;
  /* z-index: 2; */
}

.draggable-card-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 300px; */
  /* height: 260px; */
}

#exercise-1-container {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

#draggable-card-container-elements {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  /* margin: 10px; */
}

#draggable-card-container-elements .draggable-card {
  margin: 10px;
}

#draggable-card-container-connections {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 10px; */
}

#draggable-card-container-connections .draggable-card {
  margin: 10px;
}

#drop-off-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 40px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #ffffff;
  color: #080808;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: #008FFF;
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: #0073CD;
  color: white;
}

.button.reset:hover {
  background-color: #FF3333;
  color: white;
  cursor: pointer;
}

.button.reset:active {
  background-color: #CA1F1F;
  color: white;
}

.in-shadow {
   -moz-box-shadow:    inset 0 0 10px #B3B3B3;
   -webkit-box-shadow: inset 0 0 10px #B3B3B3;
   box-shadow:         inset 0 0 10px #B3B3B3;
}

@media (max-width: 1600px) {

  .target {
    width: 100px;
  }

}

</style>
