<template>
  <div class="home">
    <Lesson2CExercise msg="Exercise 2-C"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson2CExercise from '@/components/Lesson2CExercise.vue'

export default {
  name: 'Exercise2C',
  components: {
    Lesson2CExercise
  }
}
</script>
