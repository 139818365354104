<template>
  <div class="home">
    <credits msg="credits"/>
  </div>
</template>

<script>
// @ is an alias to /src
import credits from '@/components/credits.vue'

export default {
  name: 'creditsView',
  components: {
    credits
  }
}
</script>
