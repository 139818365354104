<template>
  <div class="credits-page-container">
    <div class="credits-header-container">
      <h2>Credits</h2>
    </div>
    <div class="credits-content-container">
      <p>Audio Signal Flow Credits</p>
      <p>Lessons, Content, Music and Sounds created by <a href="alexwittscheck.com">Alex Wittscheck</a></p>
      <p>Graphic Design by Lee Taylor</p>
      <p>Website Design and Development by Lee Brooks (lee@leebrooks.co)</p>
      <p>This is a Capstone Project by Alex Wittscheck presented in partial fulfillment of the requirements for the Degree Master of Music in Music Technology at Southern Utah University.</p>
      <p>Thanks to Lee Brooks and Lee Taylor for working with me to refine my vision into a functional website. Thank you to Mr. Daniel Anderegg, Dr. Stephen Meredith, and Mr. Mark Stephenson for serving on my committee. Thank you to Erin Wittscheck for being the light that I needed to continue working and making sure that I always had what I needed when I needed it. Thank you, Millie Jo, for making me smile when I needed it most. I could not have done this without all of you.</p>
      <p>©2019 Alex Wittscheck ALL RIGHTS RESERVED</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'credits',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 3.7vw;
  margin: 0;
}

a {
  /* color: rgb(78, 78, 177); */
  text-decoration: none;
}

.credits-header-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: center;
  margin-left: 30%;
}

.credits-page-container {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  width: 40%;
  text-align: left;
  margin-left: 30%;
}

</style>
