<template>
  <div class="home">
    <Lesson6BExercise msg="Exercise 6-B"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson6BExercise from '@/components/Lesson6BExercise.vue'

export default {
  name: 'Exercise6B',
  components: {
    Lesson6BExercise
  }
}
</script>
