<template>
  <div class="draggable-card" draggable="false" :id="content.name" :type="content.type">
    <h2> {{ content.name }} </h2>
    <img :src="content.image" alt="image" draggable="false">
  </div>
</template>

<script>
export default {
  name: 'draggableCard',
  props: ['content'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  margin-top: 12%;
  font-size: 120%;
}

#card-test-container {
  margin-top: 120px;
}

.draggable-card {
  /* height: 220px;
  width: 175px; */
  height: 180px;
  width: 120px;
  background-color: #FFFFFF;
  margin: 0px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 6px;
  font-size: 14px;
  /* padding-top: 16px; */
}

@media (max-width: 1600px) {

  .draggable-card {
    height: 180px;
    width: 100px;
  }

}

</style>
