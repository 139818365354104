<template>
  <div class="home">
    <allLessons msg="allLessons"/>
  </div>
</template>

<script>
// @ is an alias to /src
import allLessons from '@/components/allLessons.vue'

export default {
  name: 'allLessonsDisplay',
  components: {
    allLessons
  }
}
</script>
