<template>
  <div class="home">
    <Lesson3Content msg="Lesson 3"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson3Content from '@/components/Lesson3Content.vue'

export default {
  name: 'Lesson3',
  components: {
    Lesson3Content
  }
}
</script>
