<template>
  <div class="home">
    <certificateTemplate msg="certificateTemplate"/>
  </div>
</template>

<script>
// @ is an alias to /src
import certificateTemplate from '@/components/certificateTemplate.vue'

export default {
  name: 'certificate',
  components: {
    certificateTemplate
  }
}
</script>
