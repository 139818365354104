<template>
  <div class="home">
    <Lesson3CExercise msg="Exercise 3-C"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson3CExercise from '@/components/Lesson3CExercise.vue'

export default {
  name: 'Exercise3C',
  components: {
    Lesson3CExercise
  }
}
</script>
