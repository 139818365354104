<template>
  <div class="lesson-container">
    <div class="lesson-header-container">
      <h2>{{ lessonQuestion }}</h2>
    </div>
    <div id="lesson-content-container" v-for="(lessonNote, index) in lessonNotes" :key="index">
      <img :src="lessonNote.imageURL" alt="image" v-if="lessonNote.imageURL">
      <p>{{ lessonNote.note }}</p>
    </div>
    <div class="button-container">
      <div id="exercise-button">
        <button class="button" @click="goToExercise">Go To Exercise!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lesson-2-content',
  props: {
    msg: String
  },
  data () {
    return {
      lessonQuestion: 'The Levels of Audio',
      lessonNotes: [
        {
          id: 1,
          note: 'When it comes to electrical audio connections, there are three main audio levels to consider: mic level, line level, and speaker level.',
          imageURL: '/Graphics/Lesson 2 Slides/2_0.svg'
        },
        {
          id: 2,
          note: 'The weakest level of audio is Microphone/Instrument Level audio.',
          imageURL: '/Graphics/Lesson 2 Slides/2_1_1.svg'
        },
        {
          id: 3,
          note: 'Microphone and instrument level audio could be broken out into two separate levels of audio, but for this lesson, we will lump them all together into one category of low-level audio called mic level.',
          imageURL: ''
        },
        {
          id: 4,
          note: 'Mic level audio is output by microphones and the pickups of electric instruments such as acoustic, electric, and bass guitars.',
          imageURL: '/Graphics/Lesson 2 Slides/2_1_2.svg'
        },
        {
          id: 5,
          note: 'Mic level audio is not powerful enough to drive any audio equipment unless it is amplified by a preamp.',
          imageURL: '/Graphics/FinalSlides/2_1_3.svg'
        },
        {
          id: 6,
          note: 'A preamp is an electronic amplifier that boosts the level of mic level audio to a useable level, usually line level.',
          imageURL: ''
        },
        {
          id: 7,
          note: 'Line Level audio is higher in level than mic level audio and is the level of audio that most audio gear expects.',
          imageURL: '/Graphics/Lesson 2 Slides/2_2_1.svg'
        },
        {
          id: 8,
          note: 'Line level audio is output by preamps, electric keyboards, DJ turntables, audio consoles, audio recorders, and most audio signal processors.',
          imageURL: '/Graphics/Lesson 2 Slides/2_2_2.svg'
        },
        {
          id: 9,
          note: 'Line level audio cannot be input into any equipment that is expecting mic level audio without being attenuated (reduced in power) or it will overload the input.',
          imageURL: ''
        },
        {
          id: 10,
          note: 'All processing of an audio signal between the preamp and the speaker power amplifier usually happens at line level.',
          imageURL: '/Graphics/FinalSlides/2_2_3.svg'
        },
        {
          id: 11,
          note: 'A line level audio signal is not powerful enough to drive professional speakers. It has to go through a power amplifier to bring it up to speaker level audio.',
          imageURL: ''
        },
        {
          id: 12,
          note: 'Speaker level audio is the highest level of audio and is the level of audio signal that exists between the power amplifier and the loudspeaker.',
          imageURL: '/Graphics/Lesson 2 Slides/2_3_1.svg'
        },
        {
          id: 13,
          note: 'Speaker level audio is only output by the power amplifier.',
          imageURL: '/Graphics/Lesson 2 Slides/2_3_2.svg'
        },
        {
          id: 14,
          note: 'A power amplifier is the last step in the audio signal chain before a speaker.',
          imageURL: '/Graphics/Lesson 2 Slides/2_3_3.svg'
        },
        {
          id: 15,
          note: 'The power amp takes line level audio and boosts it to speaker level to drive a physical loudspeaker.',
          imageURL: ''
        },
        {
          id: 16,
          note: 'The power amp can be contained within a piece of equipment, but in pro audio, it is usually separate or contained within the speaker box itself.',
          imageURL: ''
        },
        {
          id: 17,
          note: 'Speaker level audio should never be connected to anything other than an appropriate loudspeaker or equipment could be damaged.',
          imageURL: '/Graphics/Lesson 2 Slides/2_4.svg'
        },
        {
          id: 18,
          note: 'To summarize, in pro audio:',
          imageURL: '/Graphics/FinalSlides/2_5_1.svg'
        },
        {
          id: 19,
          note: 'Mic level audio signals exist between the microphone or pickup and the preamp.',
          imageURL: ''
        },
        {
          id: 20,
          note: 'Line level audio signals exist between the preamp and the power amp.',
          imageURL: '/Graphics/FinalSlides/2_5_2.svg'
        },
        {
          id: 21,
          note: 'Speaker level audio signals exist between the power amp and the speaker.',
          imageURL: '/Graphics/FinalSlides/2_5_3.svg'
        },
        {
          id: 22,
          note: 'If you are ever unsure of what level of audio a piece of equipment outputs or expects at its input, read the manual.',
          imageURL: ''
        },
        {
          id: 23,
          note: 'Let’s practice!',
          imageURL: ''
        }
      ]
    }
  },
  methods: {
    goToExercise () {
      this.$router.push('/Lesson2AExercise')
    }
  },
  mounted () {
    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 64px;
  /* max-width: 430px; */
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

p {
  font-size: 24px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  text-align: left;
}

img {
  /* max-width: 1055px; */
  max-width: 844px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.lesson-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: 430px; */
}

.lesson-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 48px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #008FFF;
  color: #ffffff;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: rgb(13, 123, 207);
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: rgb(22, 107, 172);
  color: white;
}

@media (max-width: 1068px) {

  h2 {
  font-size: 56px;
  /* max-width: 430px; */
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 21px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  img {
    /* max-width: 690px; */
    max-width: 552px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 735px) {

  h2 {
  font-size: 40px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 360px) {

  h2 {
  font-size: 40px;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

</style>
