<template>
  <div class="home">
    <Lesson3AExercise msg="Exercise 3-A"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson3AExercise from '@/components/Lesson3AExercise.vue'

export default {
  name: 'Exercise3A',
  components: {
    Lesson3AExercise
  }
}
</script>
