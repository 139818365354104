<template>
  <div class="certificate-container">
    <h1>Audio Signal Flow Trainer</h1>
    <h2>Lesson 2: The Levels of Audio</h2>
    <h3>Completed by <em> {{ studentName }} </em></h3>
    <h5> {{ certTimeDate }} </h5>
  </div>
</template>

<script>
export default {
  name: 'certificateTemplate2',
  props: {
    msg: String
  },
  data () {
    return {
      studentName: '',
      certTimeDate: ''
    }
  },
  methods: {
  },
  mounted () {
    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }

    var today = new Date()
    var date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear()
    var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
    this.certTimeDate = date + ' ' + time

    this.studentName = prompt('Enter your name:')
  }
}
</script>

<style scoped>

.certificate-container {
  margin-top: 120px;
}

h1 {
  font-size: 6vw;
}

h2 {
  font-size: 4vw;
}

h3 {
  font-size: 3vw;
}

h5 {
  font-size: 1.5vw;
}

</style>
