<template>
  <div class="lesson-container">
    <div class="lesson-header-container">
      <h2>{{ lessonQuestion }}</h2>
    </div>
    <div id="lesson-content-container" v-for="(lessonNote, index) in lessonNotes" :key="index">
      <img :src="lessonNote.imageURL" alt="image" v-if="lessonNote.imageURL">
      <p>{{ lessonNote.note }}</p>
    </div>
    <div class="button-container">
      <div id="exercise-button">
        <button class="button" @click="goToExercise">Go To Exercise!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lesson-6-content',
  props: {
    msg: String
  },
  data () {
    return {
      lessonQuestion: 'Simple Live Sound Setup',
      lessonNotes: [
        {
          id: 1,
          note: 'Let’s talk about how to apply signal flow in a simple recording setup',
          imageURL: ''
        },
        {
          id: 2,
          note: 'We will use the same four sound sources that we used in the previous lesson: vocal, acoustic guitar, keyboard, and djembe.',
          imageURL: '/Graphics/FinalSlides/6_1_1.svg'
        },
        {
          id: 3,
          note: 'There are several ways to record a performance. We could mix it like a live concert and combine all of the channels together to a mono or stereo track. However, to give us flexibility, we want to record each channel separately so they can be mixed later. For now, let’s assume that our recorder is a 4-track tape machine.',
          imageURL: '/Graphics/FinalSlides/6_1_2.svg'
        },
        {
          id: 4,
          note: 'We need mics to pick up the vocal and djembe. Also, because a proper microphone sounds better than a pickup, we will use a mic on the acoustic guitar as well. We will use a DI on the keyboard. Remember that tape machines require line-level audio to record an adequate signal, so we will need a preamp for each channel.',
          imageURL: '/Graphics/FinalSlides/6_1_3.svg'
        },
        {
          id: 5,
          note: 'Each signal will go onto a separate track on the tape machine.',
          imageURL: '/Graphics/FinalSlides/6_1_4.svg'
        },
        {
          id: 6,
          note: 'Now, we can record the signals, but we will want to listen to the recording. Most music is heard in a stereo format these days. That means there are two final tracks of audio: a left track and a right track. These come out of two separate speakers and combine in the air and your head!',
          imageURL: '/Graphics/FinalSlides/6_1_5.svg'
        },
        {
          id: 7,
          note: 'To get the four channels down to two, we will need a mixer. The signals come out of the tape machine and into the line inputs of the mixer. This mixer works in the same way as the live console from the last lesson, and each of the four channels is mixed down to the master bus.',
          imageURL: '/Graphics/FinalSlides/6_1_6.svg'
        },
        {
          id: 8,
          note: 'Because we want a stereo output, we have to use a pan knob on each channel to place that channel’s signal in the stereo field. If the knob is in the center, the signal goes to both the master left and right bus equally. With the knob all the way to the left, this signal only goes to the left side of the master bus. With the knob to the right, the signal goes to the right master bus.',
          imageURL: '/Graphics/FinalSlides/6_1_7.svg'
        },
        {
          id: 9,
          note: 'Even though the pan knob is above the fader on the console, in the flow of the signal, it comes after the channel fader and all sends.',
          imageURL: '/Graphics/FinalSlides/6_1_8.svg'
        },
        {
          id: 10,
          note: 'Finally, after the master faders, the signal goes to the Master and Monitor Outputs. The monitor outputs have a separate volume control that comes after the faders and only controls the volume to your stereo monitors.',
          imageURL: '/Graphics/FinalSlides/6_1_9.svg'
        },
        {
          id: 11,
          note: 'So obviously, you can now hear everything through your speakers, but how do you record the stereo track? You can use another recorder that only holds two tracks. For illustration purposes, we will use a two-track tape machine. This tape machine will take its signal from the master outputs. So, everything that is being sent to the master bus will record onto those two tracks, left and right on the final tape machine.',
          imageURL: '/Graphics/FinalSlides/6_2.svg'
        },
        {
          id: 12,
          note: 'In modern recording, computers are usually used as recorders instead of tape machines. In these cases, a lot of this signal flow happens inside of the computer as digital audio.',
          imageURL: '/Graphics/FinalSlides/6_3_1.svg'
        },
        {
          id: 13,
          note: 'For the computer to be able to read the audio signal, it must be converted from an electrical signal to a digital signal. This is usually done inside of an audio interface that has several inputs and outputs.',
          imageURL: '/Graphics/FinalSlides/6_3_2.svg'
        },
        {
          id: 14,
          note: 'The inputs have an analog-to-digital converter, and the outputs have a digital-to-analog converter.',
          imageURL: '/Graphics/FinalSlides/6_3_3.svg'
        },
        {
          id: 15,
          note: 'This audio interface is connected to the computer by a data line such as a USB cable. This cable carries all of the digital audio to and from the computer. And all of the signal routing is done in the digital realm inside of the computer.',
          imageURL: '/Graphics/FinalSlides/6_3_4.svg'
        },
        {
          id: 16,
          note: 'As you can see, this simplifies everything. However, it can be confusing to handle digital signal routing if you do not understand how everything works in the analog realm.',
          imageURL: ''
        },
        {
          id: 17,
          note: 'Let\'s Practice!',
          imageURL: ''
        }
      ]
    }
  },
  methods: {
    goToExercise () {
      this.$router.push('/Lesson6AExercise')
    }
  },
  mounted () {
    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 64px;
  /* max-width: 430px; */
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

p {
  font-size: 24px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  text-align: left;
}

img {
  /* max-width: 1055px; */
  max-width: 844px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.lesson-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: 430px; */
}

.lesson-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 48px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #008FFF;
  color: #ffffff;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: rgb(13, 123, 207);
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: rgb(22, 107, 172);
  color: white;
}

@media (max-width: 1068px) {

  h2 {
  font-size: 56px;
  /* max-width: 430px; */
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 21px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  img {
    /* max-width: 690px; */
    max-width: 552px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 735px) {

  h2 {
  font-size: 40px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

@media (max-width: 360px) {

  h2 {
  font-size: 40px;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

  p {
    font-size: 14px;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
  }

}

</style>
