<template>
  <div class="home">
    <Lesson3BExercise msg="Exercise 3-B"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson3BExercise from '@/components/Lesson3BExercise.vue'

export default {
  name: 'Exercise3B',
  components: {
    Lesson3BExercise
  }
}
</script>
