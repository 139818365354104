<template>
  <div class="home">
    <Lesson4Content msg="Lesson 4"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson4Content from '@/components/Lesson4Content.vue'

export default {
  name: 'Lesson4',
  components: {
    Lesson4Content
  }
}
</script>
