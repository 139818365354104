<template>
  <div class="home">
    <Lesson1Exercise msg="Exercise 1"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson1Exercise from '@/components/Lesson1Exercise.vue'

export default {
  name: 'Exercise1',
  components: {
    Lesson1Exercise
  }
}
</script>
