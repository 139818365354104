<template>
  <div class="home">
    <Lesson1AExercise msg="Exercise 1-A"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson1AExercise from '@/components/Lesson1AExercise.vue'

export default {
  name: 'Exercise1A',
  components: {
    Lesson1AExercise
  }
}
</script>
