<template>
  <div class="home">
    <Lesson4AExercise msg="Exercise 4-A"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson4AExercise from '@/components/Lesson4AExercise.vue'

export default {
  name: 'Exercise4A',
  components: {
    Lesson4AExercise
  }
}
</script>
