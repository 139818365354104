import { render, staticRenderFns } from "./certificateTemplate3.vue?vue&type=template&id=4ce1f71d&scoped=true&"
import script from "./certificateTemplate3.vue?vue&type=script&lang=js&"
export * from "./certificateTemplate3.vue?vue&type=script&lang=js&"
import style0 from "./certificateTemplate3.vue?vue&type=style&index=0&id=4ce1f71d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce1f71d",
  null
  
)

export default component.exports