<template>
  <div class="home">
    <certificateTemplate5 msg="certificateTemplate5"/>
  </div>
</template>

<script>
// @ is an alias to /src
import certificateTemplate5 from '@/components/certificateTemplate5.vue'

export default {
  name: 'certificate5',
  components: {
    certificateTemplate5
  }
}
</script>
