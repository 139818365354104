import { render, staticRenderFns } from "./certificateTemplate5.vue?vue&type=template&id=00f9b7f5&scoped=true&"
import script from "./certificateTemplate5.vue?vue&type=script&lang=js&"
export * from "./certificateTemplate5.vue?vue&type=script&lang=js&"
import style0 from "./certificateTemplate5.vue?vue&type=style&index=0&id=00f9b7f5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f9b7f5",
  null
  
)

export default component.exports