<template>
  <div class="home">
    <Lesson5AExercise msg="Exercise 5-A"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson5AExercise from '@/components/Lesson5AExercise.vue'

export default {
  name: 'Exercise5A',
  components: {
    Lesson5AExercise
  }
}
</script>
