<template>
  <div class="home">
    <certificateTemplate4 msg="certificateTemplate4"/>
  </div>
</template>

<script>
// @ is an alias to /src
import certificateTemplate4 from '@/components/certificateTemplate4.vue'

export default {
  name: 'certificate4',
  components: {
    certificateTemplate4
  }
}
</script>
