<template>
  <div class="home">
    <Lesson2AExercise msg="Exercise 2-A"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson2AExercise from '@/components/Lesson2AExercise.vue'

export default {
  name: 'Exercise2A',
  components: {
    Lesson2AExercise
  }
}
</script>
