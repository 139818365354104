<template>
  <div id="exercise-1-container">
    <!-- <p>Child element count test: {{ connectionsCount }} </p> -->
    <audio controls autoplay>
      <source src="/sounds/Bed1.mp3" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>
    <div class="lesson-intro-container">
      <div class="page-header-container">
        <div class="header-container">
          <button class="lesson-selection-button" @click="decrementLessonPart">prev</button>
          <h2>{{ exercisePageHeader }} {{ exerciseContent[lessonPartIndex].exercisePart }}</h2>
          <button class="lesson-selection-button" @click="incrementLessonPart">next</button>
        </div>
      </div>
      <div class="lesson-instructions-container">
        <p> {{ lessonInstructions }}</p>
      </div>
    </div>
    <div class="draggable-card-parent-container">
      <fieldset>
        <legend>Audio Elements</legend>
        <div id="draggable-card-container-elements" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
          <draggable-card v-for="(content, index) in exerciseContent[lessonPartIndex].elementCardDatas" :content="content" :lessonPartIndex="lessonPartIndex" :key="index" draggable="true"/>
        </div>
      </fieldset>
      <fieldset>
        <legend>Connections</legend>
        <div id="draggable-card-container-connections" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
          <draggable-card v-for="(content, index) in connectionCardDatas" :content="content" :key="index" draggable="true"/>
        </div>
      </fieldset>
    </div>
    <!-- Had problems with making a target component, do it stupidly with v-ifs for now, make component work later-->
    <div v-if="lessonPartIndex==0" id="drop-off-container" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
      <div class='target in-shadow' id="targ1" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ2" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ3" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ4" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ5" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
    </div>
    <div v-if="lessonPartIndex==1" id="drop-off-container" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
      <div class='target in-shadow' id="targ1" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ2" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ3" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ4" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ5" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ6" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ7" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
    </div>
    <div v-if="lessonPartIndex==2" id="drop-off-container" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
      <div class='target in-shadow' id="targ1" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ2" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ3" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ4" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ5" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ6" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ7" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ8" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ9" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
    </div>
    <div v-if="lessonPartIndex==3" id="drop-off-container" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
      <div class='target in-shadow' id="targ1" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ2" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ3" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ4" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ5" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ6" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ7" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ8" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ9" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ10" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ11" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
    </div>
    <div class="button-container">
      <div id="test">
        <button class="button" @click="submitAnswer">submit</button>
      </div>
      <div class="reset-button-container">
        <button class="button reset" @click="resetPage">start over</button>
      </div>
    </div>
  </div>
</template>

<script>
import draggableCard from './draggableCard.vue'

export default {
  name: 'lesson-1-exercise',
  props: {
    msg: String
  },
  components: {
    draggableCard
  },
  data () {
    return {
      exercisePageHeader: 'Exercise 1: ',
      lessonInstructions: 'Drag the pieces into the correct order of audio signal flow for when a person is listening to music from their phone.',
      lessonPartIndex: 0,
      totalLessonParts: 4,
      draggedCard: '',
      orderCheck: 0,
      partAComplete: 0,
      partBComplete: 0,
      partCComplete: 0,
      partDComplete: 0,
      dragCardKey: 0,
      connectionsCount: '',
      bedLoopAudio: new Audio('/sounds/Bed1.mp3'),
      winAudio: new Audio('/sounds/Win.mp3'),
      loseAudio: new Audio('/sounds/Lose.mp3'),
      userAnswers: [
        { // part A
          userAnswer: []
        },
        { // part B
          userAnswer: []
        },
        { // part C
          userAnswer: []
        },
        { // part D
          userAnswer: []
        }
      ],
      elementCardOptions: [
        {
          name: 'Listener',
          image: '/Graphics/Icons/Avatar.svg',
          type: 'element',
          id: 'listener-card',
          parts: ['A', 'B', 'C', 'D']
        },
        {
          name: 'Phone',
          image: '/Graphics/Icons/Phone.svg',
          type: 'element',
          id: 'phone-card',
          parts: ['A', 'B']
        },
        {
          name: 'Headphones',
          image: '/Graphics/Icons/headphones.svg',
          type: 'element',
          id: 'headphones-card',
          parts: ['A']
        },
        {
          name: 'Speakers',
          image: '/Graphics/Icons/Speaker.svg',
          type: 'element',
          id: 'speakers-card',
          parts: ['B', 'C', 'D']
        },
        {
          name: 'Radio',
          image: '/Graphics/Icons/Mixer.svg',
          type: 'element',
          id: 'radio-card',
          parts: ['B']
        },
        {
          name: 'Mouth',
          image: '/Graphics/Icons/Avatar Vocal.svg',
          type: 'element',
          id: 'mouth-card',
          parts: ['C']
        },
        {
          name: 'Mixer',
          image: '/Graphics/Icons/Mixer.svg',
          type: 'element',
          id: 'mixer-card',
          parts: ['C', 'D']
        },
        {
          name: 'Mic',
          image: '/Graphics/Icons/Mic.svg',
          type: 'element',
          id: 'mic-card',
          parts: ['C', 'D']
        },
        {
          name: 'Amp',
          image: '/Graphics/Icons/Amp.svg',
          type: 'element',
          id: 'amp-card',
          parts: ['D']
        },
        {
          name: 'Electric Guitar',
          image: '/Graphics/Icons/Guitar.svg',
          type: 'element',
          id: 'electricguitar-card',
          parts: ['D']
        }
      ],
      connectionCardDatas: [
        {
          name: 'Electric',
          image: '/Graphics/Icons/Wire1.svg',
          type: 'connection',
          id: 'electricconnection-card'
        },
        {
          name: 'Acoustic',
          image: '/Graphics/Icons/Wave.svg',
          type: 'connection',
          id: 'acousticconnection-card'
        }
      ],
      exerciseContent: [
        {
          exercisePart: 'A',
          exerciseDescription: 'This is what\'s up.',
          answerOrder: ['Phone', 'Electric', 'Headphones', 'Acoustic', 'Listener'],
          elementCardDatas: []
        },
        {
          exercisePart: 'B',
          exerciseDescription: 'Let\'s throw a stereo with speakers into the mix.',
          answerOrder: ['Phone', 'Electric', 'Radio', 'Electric', 'Speakers', 'Acosutic', 'Listener'],
          elementCardDatas: []
        },
        {
          exercisePart: 'C',
          exerciseDescription: 'How about someone speaking through a microphone?',
          answerOrder: ['Mouth', 'Acoustic', 'Mic', 'Electric', 'Mixer', 'Electric', 'Speakers', 'Acoustic', 'Listener'],
          elementCardDatas: []
        },
        {
          exercisePart: 'D',
          exerciseDescription: 'What does the signal look like for someone playing an electrig guitar?',
          answerOrder: ['Electric Guitar', 'Electric', 'Amp', 'Acoustic', 'Mic', 'Electric', 'Mixer', 'Electric', 'Speakers', 'Acoustic', 'Listener'],
          elementCardDatas: []
        }
      ]
    }
  },
  methods: {
    addElectricConnection () {
      this.connectionCardDatas.push({ // unshift instead of push, but bug in WATCH gets two acoustics
        name: 'Electric',
        image: '/Graphics/Icons/Wire1.svg',
        type: 'connection'
      })
    },
    addAcousticConnection () {
      this.connectionCardDatas.push({
        name: 'Acoustic',
        image: '/Graphics/Icons/Wave.svg',
        type: 'connection'
      })
    },
    incrementLessonPart () {
      if (this.lessonPartIndex < (this.totalLessonParts - 1)) {
        this.lessonPartIndex += 1
      }
    },
    decrementLessonPart () {
      if (this.lessonPartIndex > 0) {
        this.lessonPartIndex -= 1
      }
    },
    startOfDrag (e) {
      this.draggedCard = e.target
    },
    onDragOver (e) {
      e.preventDefault()
      e.target.style.borderColor = 'red'
    },
    onDragLeave (e) {
      e.preventDefault()
      e.target.style.borderColor = 'black'
    },
    onDragDrop (e) {
      if (e.target.classList.contains('target')) {
        e.target.appendChild(this.draggedCard)
        e.target.style.borderColor = 'blue'
      }
      this.connectionsCount = document.getElementById('draggable-card-container-connections').childElementCount
    },
    submitAnswer () {
      // clear array
      this.userAnswers[this.lessonPartIndex].userAnswer = []
      // check 1
      var par1 = document.getElementById('targ1')
      if (par1.childNodes.length === 1) {
        var chi1 = par1.childNodes[0]
        this.userAnswers[this.lessonPartIndex].userAnswer.push(chi1.id)
      } else {
        alert('Spot #1 empty')
      }
      // check 2
      var par2 = document.getElementById('targ2')
      if (par2.childNodes.length === 1) {
        var chi2 = par2.childNodes[0]
        this.userAnswers[this.lessonPartIndex].userAnswer.push(chi2.id)
      } else {
        alert('Spot #2 empty')
      }
      // check 3
      var par3 = document.getElementById('targ3')
      if (par3.childNodes.length === 1) {
        var chi3 = par3.childNodes[0]
        this.userAnswers[this.lessonPartIndex].userAnswer.push(chi3.id)
      } else {
        alert('Spot #3 empty')
      }
      // check 4
      var par4 = document.getElementById('targ4')
      if (par4.childNodes.length === 1) {
        var chi4 = par4.childNodes[0]
        this.userAnswers[this.lessonPartIndex].userAnswer.push(chi4.id)
      } else {
        alert('Spot #4 empty')
      }
      // check 5
      var par5 = document.getElementById('targ5')
      if (par5.childNodes.length === 1) {
        var chi5 = par5.childNodes[0]
        this.userAnswers[this.lessonPartIndex].userAnswer.push(chi5.id)
      } else {
        alert('Spot #5 empty')
      }
      // check answer
      console.log(this.exerciseContent[this.lessonPartIndex].answerOrder)
      console.log(this.userAnswers[this.lessonPartIndex].userAnswer)
    },
    resetPage () {
      location.reload()
    }
    // playWinSound (sound) {
    //   if(sound) {
    //     var audio = new Audio(sound);
    //     audio.play();
    //   }
    // },
    // playLoseSound (sound) {
    //   if(sound) {
    //     var audio = new Audio(sound);
    //     audio.play();
    //   }
    // }
  },
  mounted () {
    for (var cardOption in this.elementCardOptions) {
      if (this.exerciseContent[this.lessonPartIndex].answerOrder.includes(this.elementCardOptions[cardOption].name)) {
        this.exerciseContent[this.lessonPartIndex].elementCardDatas.push(this.elementCardOptions[cardOption])
      }
    }

    this.connectionsCount = document.getElementById('draggable-card-container-connections').childElementCount
  },
  // computed: {
  //   countConnectionCards () {
  //     let numConnections = document.getElementById('draggable-card-container-connections').childElementCount
  //     return this.connectionsCount = numConnections
  //   }
  // },
  watch: {
    lessonPartIndex: function () {
      this.exerciseContent[this.lessonPartIndex].elementCardDatas = []
      for (var cardOption in this.elementCardOptions) {
        if (this.exerciseContent[this.lessonPartIndex].answerOrder.includes(this.elementCardOptions[cardOption].name)) {
          this.exerciseContent[this.lessonPartIndex].elementCardDatas.push(this.elementCardOptions[cardOption])
        }
      }
    },
    connectionsCount: function () { // check which connection was dropped and add another
      if (this.connectionsCount === 1) {
        let currentChild = document.getElementById('draggable-card-container-connections').childNodes[0].id
        console.log(currentChild)
        if (currentChild === 'Electric') {
          this.addAcousticConnection()
        } else {
          this.addElectricConnection()
        }
      }
    }
  },
  computed: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 1.5vw;
}

.page-header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  border-radius: 4px;
  width: 40%;
  height: 40px;
}

.lesson-selection-button {
  margin: 14px 0px;
  width: 80px;
  height: 100%;
  /* border: 2px solid black; */
  /* border-radius: 6px; */
  border: none;
  letter-spacing: 4.72px;
  background-color: transparent;
  color: #080808;
  font-size: 18px;
  outline: none;
}

.lesson-selection-button:hover {
  background-color: #B3B3B3;
}

.lesson-selection-button:active {
  background-color: darkgray;
}

fieldset {
  margin: 10px;
}

.target {
  position: relative;
  display: inline-block;
  /* width: 175px; */
  /* height: 220px; */
  height: 180px;
  width: 120px;
  margin: 10px;
  padding: 0px;
  /* border: 1px dashed black; */
  border-radius: 6px;
  background-color: #E7E7E7;
}

.target h1 {
  font-size: 84px;
  color: #D2D2D2;
}

.target .draggable-card {
  position: absolute;
  left: 0px;
  top: 0px;
  /* z-index: 2; */
}

.draggable-card-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 300px; */
  height: 260px;
}

#exercise-1-container {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

#draggable-card-container-elements {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 10px; */
}

#draggable-card-container-elements .draggable-card {
  margin: 10px;
}

#draggable-card-container-connections {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 10px; */
}

#draggable-card-container-connections .draggable-card {
  margin: 10px;
}

#drop-off-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 40px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #ffffff;
  color: #080808;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: #008FFF;
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: #0073CD;
  color: white;
}

.button.reset:hover {
  background-color: #FF3333;
  color: white;
  cursor: pointer;
}

.button.reset:active {
  background-color: #CA1F1F;
  color: white;
}

.in-shadow {
   -moz-box-shadow:    inset 0 0 10px #B3B3B3;
   -webkit-box-shadow: inset 0 0 10px #B3B3B3;
   box-shadow:         inset 0 0 10px #B3B3B3;
}

</style>

<!-- not used anymore

/* .box {
  position: relative;
  display: inline-block;
  float: left;
  width: 100px;
  height: 100px;
  margin: 10px;
  background-color: black;
} */

#draggable-card-container {
  position: relative;
  display: inline-block;
  width: 80vw;
  height: 220px;
  margin: 10px;
  padding: 10px;
  border: 1px dashed black;
}

-->
