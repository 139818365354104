<template>
  <div class="home">
    <certificateTemplate2 msg="certificateTemplate2"/>
  </div>
</template>

<script>
// @ is an alias to /src
import certificateTemplate2 from '@/components/certificateTemplate2.vue'

export default {
  name: 'certificate2',
  components: {
    certificateTemplate2
  }
}
</script>
