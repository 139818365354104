<template>
  <div id="exercise-1-container">
    <!-- <p>Child element count test: {{ connectionsCount }} </p> -->
    <div class="lesson-intro-container">
      <div class="page-header-container">
        <div class="header-container">
          <h2>{{ exercisePageHeader }} {{ exercisePart }}</h2>
        </div>
      </div>
      <div class="lesson-instructions-container">
        <p> {{ lessonInstructions }}</p>
        <p> {{ lessonPartText }} </p>
        <div class="hint-container">
          <h3 class="hint-header">Hints:</h3>
          <p v-for="(hint, index) in hints" :key="index" class="hints"> {{ hint }} </p>
        </div>
      </div>
    </div>
    <div class="draggable-card-parent-container">
      <fieldset>
        <legend>Element/Line Outputs</legend>
        <div id="draggable-card-container-elements" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
          <draggable-card v-for="(content, index) in elementCardDatas" :content="content" :lessonPartIndex="lessonPartIndex" :key="index" draggable="true"/>
        </div>
      </fieldset>
      <!-- <fieldset>
        <legend>Connections</legend>
        <div id="draggable-card-container-connections" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
          <draggable-card v-for="(content, index) in connectionCardDatas" :content="content" :key="index" draggable="true"/>
        </div>
      </fieldset> -->
    </div>
    <!-- Had problems with making a target component, do it stupidly with v-ifs for now, make component work later-->
    <div v-if="lessonPartIndex==0" id="drop-off-container" @drag="startOfDrag" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop">
      <div class='target in-shadow' id="targ1" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ2" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ3" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ4" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ5" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ6" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ7" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ8" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
      <div class='target in-shadow' id="targ9" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDragDrop"></div>
    </div>
    <div class="button-container">
      <div id="test">
        <button class="button gotonext" @click="goToPrev">Prev Lesson</button>
      </div>
      <div id="test">
        <button class="button" @click="submitAnswer">submit</button>
      </div>
      <div class="reset-button-container">
        <button class="button reset" @click="resetPage">start over</button>
      </div>
      <div v-show="partComplete">
      <button class="button gotonext" @click="goToNext">Next Lesson</button>
    </div>
    </div>
  </div>
</template>

<script>
import draggableCard from './draggableCard.vue'
// import { mapState } from 'vuex'

export default {
  name: 'lesson-1-a-exercise',
  props: {
    msg: String
  },
  components: {
    draggableCard
  },
  data () {
    return {
      exercisePageHeader: 'Exercise 3 - ',
      lessonInstructions: 'Drag these icons in the order that the audio signal flows from left to right. Make sure you put the correct audio type and level between each element!',
      lessonPartText: 'What does the audio signal flow inside of a guitar amp look like?',
      lessonPartIndex: 0,
      totalLessonParts: 4,
      draggedCard: '',
      orderCheck: 0,
      partComplete: 0,
      winAudio: '/sounds/Win.mp3',
      loseAudio: '/sounds/Lose.mp3',
      buttonClickAudio: '/sounds/Click1.mp3',
      dropAudio: '/sounds/Click2.mp3',
      bedLoopAudio: '/sounds/Bed1.mp3',
      connectionsCount: '',
      userAnswer: [],
      exercisePart: 'B',
      exerciseDescription: 'This is what\'s up.',
      answerOrder: ['Electric Guitar', 'Mic Level', 'Preamp', 'Line Level', 'EQ', 'Line Level', 'Power Amp', 'Speaker Level', 'Speaker'],
      hints: [
        'Even though it is coming out of an instrument, the signal that is output by the electric guitar can be considered mic level audio.',
        'The signal must be at line level before it goes into EQ.',
        'The Preamp and Power Amp both raise the audio signal to the next level.'
      ],
      connectionCardDatas: [
        {
          name: 'Acoustic',
          image: '/Graphics/Icons/Wave.svg',
          type: 'connection',
          id: 'acousticconnection-card'
        }
      ],
      elementCardDatas: [
        {
          name: 'Line Level',
          image: '/Graphics/Icons/Wave_Md.svg',
          type: 'connection',
          id: 'linelevel-card'
        },
        {
          name: 'Preamp',
          image: '/Graphics/Icons/PreAmp.svg',
          type: 'element',
          id: 'preamp-card'
        },
        {
          name: 'Speaker Level',
          image: '/Graphics/Icons/Wave_Lg.svg',
          type: 'connection',
          id: 'speakerlevel-card'
        },
        {
          name: 'Mic Level',
          image: '/Graphics/Icons/Wave_Sm.svg',
          type: 'connection',
          id: 'miclevel-card'
        },
        {
          name: 'Electric Guitar',
          image: '/Graphics/Icons/Guitar.svg',
          type: 'element',
          id: 'electricguitar-card'
        },
        {
          name: 'Line Level',
          image: '/Graphics/Icons/Wave_Md.svg',
          type: 'connection',
          id: 'linelevel-card'
        },
        {
          name: 'EQ',
          image: '/Graphics/Icons/EQ.svg',
          type: 'element',
          id: 'eq-card'
        },
        {
          name: 'Speaker',
          image: '/Graphics/Icons/Speaker.svg',
          type: 'element',
          id: 'speaker-card'
        },
        {
          name: 'Power Amp',
          image: '/Graphics/Icons/PowerAmp.svg',
          type: 'element',
          id: 'poweramp-card'
        }
      ]
    }
  },
  // computed: mapState(['state']),
  methods: {
    addAcousticConnection () {
      this.connectionCardDatas.push({
        name: 'Acoustic',
        image: '/Graphics/Icons/Wave.svg',
        type: 'connection'
      })
    },
    startOfDrag (e) {
      this.draggedCard = e.target
    },
    onDragOver (e) {
      e.preventDefault()
      e.target.style.borderColor = 'red'
    },
    onDragLeave (e) {
      e.preventDefault()
      e.target.style.borderColor = 'black'
    },
    onDragDrop (e) {
      if (e.target.classList.contains('target')) {
        e.target.appendChild(this.draggedCard)
        e.target.style.borderColor = 'blue'
      }
      // this.connectionsCount = document.getElementById('draggable-card-container-connections').childElementCount
      this.playSoundAsync(this.dropAudio)
    },
    submitAnswer () {
      this.playSoundAsync(this.buttonClickAudio)
      // clear array
      this.userAnswer = []
      // check 1
      var par1 = document.getElementById('targ1')
      if (par1.childNodes.length === 1) {
        var chi1 = par1.childNodes[0]
        this.userAnswer.push(chi1.id)
      } else {
        alert('Spot #1 empty')
      }
      // check 2
      var par2 = document.getElementById('targ2')
      if (par2.childNodes.length === 1) {
        var chi2 = par2.childNodes[0]
        this.userAnswer.push(chi2.id)
      } else {
        alert('Spot #2 empty')
      }
      // check 3
      var par3 = document.getElementById('targ3')
      if (par3.childNodes.length === 1) {
        var chi3 = par3.childNodes[0]
        this.userAnswer.push(chi3.id)
      } else {
        alert('Spot #3 empty')
      }
      // check 4
      var par4 = document.getElementById('targ4')
      if (par4.childNodes.length === 1) {
        var chi4 = par4.childNodes[0]
        this.userAnswer.push(chi4.id)
      } else {
        alert('Spot #4 empty')
      }
      // check 5
      var par5 = document.getElementById('targ5')
      if (par5.childNodes.length === 1) {
        var chi5 = par5.childNodes[0]
        this.userAnswer.push(chi5.id)
      } else {
        alert('Spot #5 empty')
      }
      // check 6
      var par6 = document.getElementById('targ6')
      if (par6.childNodes.length === 1) {
        var chi6 = par6.childNodes[0]
        this.userAnswer.push(chi6.id)
      } else {
        alert('Spot #6 empty')
      }
      // check 5
      var par7 = document.getElementById('targ7')
      if (par7.childNodes.length === 1) {
        var chi7 = par7.childNodes[0]
        this.userAnswer.push(chi7.id)
      } else {
        alert('Spot #7 empty')
      }
      // check 5
      var par8 = document.getElementById('targ8')
      if (par8.childNodes.length === 1) {
        var chi8 = par8.childNodes[0]
        this.userAnswer.push(chi8.id)
      } else {
        alert('Spot #8 empty')
      }
      // check 5
      var par9 = document.getElementById('targ9')
      if (par9.childNodes.length === 1) {
        var chi9 = par9.childNodes[0]
        this.userAnswer.push(chi9.id)
      } else {
        alert('Spot #9 empty')
      }
      // check answer
      console.log(JSON.stringify(this.answerOrder))
      console.log(JSON.stringify(this.userAnswer))
      if (this.userAnswer.length !== this.answerOrder.length) {
        alert('Hm. You may have forgotten something. Check your answer again!')
      } else {
        if (JSON.stringify(this.answerOrder) === JSON.stringify(this.userAnswer)) {
          this.partComplete = 1
          this.$store.commit('ex3ptBcomplete')
          alert('Correct! Great job!')
          this.$store.commit('loopStop')
          this.playSoundAsync(this.winAudio)
          // this.$store.commit('loopPlay')
        } else {
          alert('Wrong! Try again.')
          this.$store.commit('loopStop')
          this.playSoundAsync(this.loseAudio)
          // this.$store.commit('loopPlay')
        }
      }
    },
    goToNext () {
      this.playSoundAsync(this.buttonClickAudio)
      if (this.partComplete === 1) {
        this.$router.push('/Lesson3CExercise')
      }
    },
    goToPrev () {
      this.playSoundAsync(this.buttonClickAudio)
      this.$router.push('/Lesson3AExercise')
    },
    resetPage () {
      this.playSoundAsync(this.buttonClickAudio)
      location.reload()
    },
    playSoundAsync (url) {
      new Audio(url).play()
    },
    loopStop () {
      this.bedLoop.pause()
    }
  },
  mounted () {
    this.bedLoop = new Audio(this.bedLoopAudio) // .play()
    this.bedLoop.play()

    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
    for (var cardOption in this.elementCardOptions) {
      if (this.exerciseContent[this.lessonPartIndex].answerOrder.includes(this.elementCardOptions[cardOption].name)) {
        this.exerciseContent[this.lessonPartIndex].elementCardDatas.push(this.elementCardOptions[cardOption])
      }
    }

    // this.connectionsCount = document.getElementById('draggable-card-container-connections').childElementCount
  },
  watch: {
    // connectionsCount: function () { // check which connection was dropped and add another
    //   if (this.connectionsCount === 0) {
    //     this.addAcousticConnection()
    //   }
    // }
  },
  beforeDestroy () {
    this.bedLoop.pause()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  font-size: 3.7vw;
  margin: 0;
}

.hint-container {
  margin-bottom: 12px;
}

.hint-header {
  margin: 0;
}

.hints {
  margin: 0;
}

.page-header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid black; */
  /* border-radius: 4px; */
  /* width: 40%; */
  /* height: 40px; */
}

.lesson-selection-button {
  margin: 14px 0px;
  width: 80px;
  height: 100%;
  /* border: 2px solid black; */
  /* border-radius: 6px; */
  border: none;
  letter-spacing: 4.72px;
  background-color: transparent;
  color: #080808;
  font-size: 18px;
  outline: none;
}

.lesson-selection-button:hover {
  background-color: #B3B3B3;
}

.lesson-selection-button:active {
  background-color: darkgray;
}

fieldset {
  margin: 10px;
}

.target {
  position: relative;
  display: inline-block;
  /* width: 175px; */
  /* height: 220px; */
  height: 180px;
  width: 120px;
  margin: 10px;
  padding: 0px;
  /* border: 1px dashed black; */
  border-radius: 6px;
  background-color: #E7E7E7;
}

.target h1 {
  font-size: 84px;
  color: #D2D2D2;
}

.target .draggable-card {
  position: absolute;
  left: 0px;
  top: 0px;
  /* z-index: 2; */
}

.draggable-card-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 300px; */
  height: 260px;
}

#exercise-1-container {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

#draggable-card-container-elements {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 10px; */
}

#draggable-card-container-elements .draggable-card {
  margin: 10px;
}

#draggable-card-container-connections {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 10px; */
}

#draggable-card-container-connections .draggable-card {
  margin: 10px;
}

#drop-off-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  margin: 14px;
  width: 140px;
  height: 40px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #ffffff;
  color: #080808;
  font-size: 16px;
  outline: none;
}

.button:hover {
  background-color: #008FFF;
  color: white;
  cursor: pointer;
}

.button:active {
  background-color: #0073CD;
  color: white;
}

.button.reset:hover {
  background-color: #FF3333;
  color: white;
  cursor: pointer;
}

.button.reset:active {
  background-color: #CA1F1F;
  color: white;
}

.in-shadow {
   -moz-box-shadow:    inset 0 0 10px #B3B3B3;
   -webkit-box-shadow: inset 0 0 10px #B3B3B3;
   box-shadow:         inset 0 0 10px #B3B3B3;
}

@media (max-width: 1600px) {

  .target {
    width: 100px;
  }

}

</style>
