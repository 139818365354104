<template>
  <div class="home">
    <Lesson2Content msg="Lesson 2"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Lesson2Content from '@/components/Lesson2Content.vue'

export default {
  name: 'Lesson2',
  components: {
    Lesson2Content
  }
}
</script>
